import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';

const useStyles = makeStyles(() => ({
  ...Typography,
  root: {
    fontFamily: 'Elza',
    width: '100%',
    padding: '5rem 5rem',
    background: COLORS.BLUE[5],
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  container: {
    width: '100%'
  },
  imgContainer: {
    height: '3rem'
  },
  topImg: {
    height: '100%',
    width: 'auto'
  },
  bottomImg: {
    height: '100%',
    width: 'auto',
    transform: 'rotate(180deg)'
  },
  quoteContainer: {
    width: '100%',
    padding: '0 2.5rem',
    marginTop: '2rem',
    marginBottom: '2rem'
  },
  bottomContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '2.5rem'
  },
  quote: {
    ...Typography.h4,
    fontWeight: '400'
  }
}));

function DefaultQuote(props) {
  const classes = useStyles();
  const { quote, author, authorInfo } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.imgContainer}>
          <img
            data-aos="fade-down"
            className={classes.topImg}
            src="/static/arrows_down.png"
            alt="arrows"
          />
        </div>
        <div data-aos="fade-right" className={classes.quoteContainer}>
          <h4 className={classes.quote}>{quote}</h4>
        </div>
        <div className={classes.bottomContainer}>
          <div data-aos="fade-right" className={classes.authorContainer}>
            <h5 className={classes.h5}>{author}</h5>
            <p className={classes.p}>{authorInfo}</p>
          </div>
          <div className={classes.imgContainer}>
            <img
              data-aos="fade-up"
              className={classes.bottomImg}
              src="/static/arrows_down.png"
              alt="arrows"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DefaultQuote.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  authorInfo: PropTypes.string
};

export default DefaultQuote;
