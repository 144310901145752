import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Settings from './Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  },
  logo: {
    height: 45
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();

  return (
    <>
      <AppBar className={clsx(classes.root, className)} {...rest}>
        <Toolbar className={classes.toolbar}>
          <Hidden mdDown>
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          </Hidden>
          <Box ml={2} flexGrow={1} />
          <Settings />
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
