import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import authService from 'src/services/authService';

function BrandAuthGuard({ children }) {
  const account = useSelector((state) => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }
  if (account.user.email === 'admin@relentlo.com') {
    return <Redirect to="/app/admin" />;
  }
  if (account.user.type === 'game') {
    return <Redirect to="/app" />;
  }
  if (
    account.user.type === 'studio' &&
    !authService.getStudioGame() &&
    !authService.getUser()
  ) {
    return <Redirect to="/app/studio" />;
  }
  return children;
}

BrandAuthGuard.propTypes = {
  children: PropTypes.any
};

export default BrandAuthGuard;
