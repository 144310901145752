import axios from 'src/utils/axios';
import { SESSION_EXPIRED } from 'src/actions/accountActions';
import { DELETE_POST_FROM_ITEM, getItems } from 'src/actions/itemActions';

export const POST_REQUEST = '@post/post-request';
export const POST_SUCCESS = '@post/post-success';
export const DELETE_POST = '@post/delete-post';
export const SET_POST_DATA = '@post/set-post-data';
export const CLEAR_POST_DATA = '@post/clear-post-data';
export const CLEAR_POSTS = '@post/clear-posts';

export function getPosts(history) {
  return async (dispatch) => {
    try {
      dispatch({ type: POST_REQUEST });
      const posts = await axios.get('/getPosts');
      dispatch({
        type: POST_SUCCESS,
        payload: {
          posts: posts.data
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        if (history) {
          history.push('/login');
        }
      }
      throw error;
    }
  };
}

export function getAllPosts(history) {
  return async (dispatch) => {
    try {
      dispatch({ type: POST_REQUEST });
      const allPosts = await axios.get('/getAllPosts');
      dispatch({
        type: POST_SUCCESS,
        payload: {
          allPosts: allPosts.data
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        if (history) {
          history.push('/login');
        }
      }
      throw error;
    }
  };
}

export function getRecommendedPosts(history) {
  return async (dispatch) => {
    try {
      dispatch({ type: POST_REQUEST });
      const recommendedPosts = await axios.get('/getRecommendedPosts');
      dispatch({
        type: POST_SUCCESS,
        payload: {
          recommendedPosts: recommendedPosts.data
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        if (history) {
          history.push('/login');
        }
      }
      throw error;
    }
  };
}

export function setPostData(postData) {
  return (dispatch) => {
    dispatch({
      type: SET_POST_DATA,
      payload: {
        postData
      }
    });
  };
}

export function clearPostData() {
  return (dispatch) => {
    dispatch({ type: CLEAR_POST_DATA });
  };
}

export function addPost(history, formData) {
  return async (dispatch) => {
    try {
      dispatch({ type: POST_REQUEST });
      let postID = await axios.post('/addPost', formData);
      dispatch(getPosts(history));
      dispatch(getAllPosts(history));
      dispatch(getItems(history));
      // dispatch(getRecommendedPosts(history));
      return postID.data.postID;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function editPost(history, formData) {
  return async (dispatch) => {
    try {
      dispatch({ type: POST_REQUEST });
      let postID = await axios.post('/editPost', formData);
      dispatch(getPosts());
      dispatch(getAllPosts());
      return postID.data.postID;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function deletePost(history, postID) {
  return async (dispatch) => {
    try {
      history.push('/app/explore/browse');
      dispatch({
        type: DELETE_POST,
        payload: {
          postID
        }
      });
      dispatch({
        type: DELETE_POST_FROM_ITEM,
        payload: {
          postID
        }
      });
      await axios.post('/deletePost', { postID });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}
