import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import { formatGameData } from 'src/utils/formatData';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          this.setStudioGame(null);
          this.setUser(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();
    const game = this.getStudioGame();
    const user = this.getUser();

    if (!accessToken) {
      return;
    }

    if (game) {
      this.setStudioGame(game);
    }

    if (user) {
      this.setUser(user);
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
      this.setStudioGame(null);
      this.setUser(null);
    }
  }

  loginWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post('/login', { email, password })
        .then((response) => {
          if (!response.data.error) {
            this.setSession(response.data.token);
            resolve(response.data.token);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      axios
        .get('/user')
        .then((response) => {
          if (!response.data.error) {
            formatGameData(response.data);
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  register = (newUserData) =>
    new Promise((resolve, reject) => {
      axios
        .post('/signup', newUserData)
        .then((response) => {
          if (!response.data.error) {
            // this.setSession(response.data.token);
            resolve(response.data.token);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
    this.setStudioGame(null);
    this.setUser(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  setStudioGame = (game) => {
    if (game) {
      localStorage.setItem('game', game);
      axios.defaults.headers.common['game'] = game;
    } else {
      localStorage.removeItem('game');
      delete axios.defaults.headers.common['game'];
    }
  };

  setUser = (user) => {
    if (user) {
      localStorage.setItem('user', user);
      axios.defaults.headers.common['user'] = user;
    } else {
      localStorage.removeItem('user');
      delete axios.defaults.headers.common['user'];
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');
  getStudioGame = () => localStorage.getItem('game');
  getUser = () => localStorage.getItem('user');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  resetPassword = (email) =>
    new Promise((resolve, reject) => {
      axios
        .post('/resetPassword', { email })
        .then((response) => {
          if (!response.data.error) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
