import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';

const useStyles = makeStyles(() => ({
  ...Typography,
  root: {
    fontFamily: 'Elza',
    width: '100%',
    paddingTop: '3rem',
    paddingLeft: '3rem',
    paddingRight: '3rem',
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    ...Typography.h5,
    color: COLORS.PRIMARY.default,
    textAlign: 'center',
    maxWidth: '200px'
  },
  description: {
    ...Typography.p,
    color: COLORS.PRIMARY.default,
    textAlign: 'center',
    marginTop: '1rem'
  },
  imgContainer: {
    height: '14rem',
    width: 'auto',
    margin: '0 auto'
  },
  image: {
    maxHeight: '100%',
    width: 'auto'
  }
}));

function MobileCTA(props) {
  const classes = useStyles();
  const { title, description, img, bg = COLORS.PRIMARY[5] } = props;
  return (
    <div className={classes.root} style={{ background: bg }}>
      <h5 className={classes.title}>{title}</h5>
      <p className={classes.description}>{description}</p>
      <div className={classes.imgContainer}>
        <img className={classes.image} src={img} alt="Character" />
      </div>
    </div>
  );
}

MobileCTA.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  bg: PropTypes.string
};

export default MobileCTA;
