import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
// import notificationsReducer from './notificationsReducer';
// import chatReducer from './chatReducer';
// import mailReducer from './mailReducer';
// import kanbanReducer from './kanbanReducer';
import itemReducer from './itemReducer';
import postReducer from './postReducer';
import campaignReducer from './campaignReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  // notifications: notificationsReducer,
  item: itemReducer,
  post: postReducer,
  campaign: campaignReducer,
  // chat: chatReducer,
  // mail: mailReducer,
  // kanban: kanbanReducer,
  form: formReducer
});

export default rootReducer;
