import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import DefaultLogoInverted from 'src/webkit/foundation/logos/logo-default-inverted.svg';
import { COLORS } from 'src/webkit/foundation/Colors';
import { motion } from 'framer-motion';
import { max, min } from 'lodash';
import Typography from 'src/webkit/foundation/Typography';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Elza',
    width: '100%',
    background: COLORS.PRIMARY.default,
    paddingTop: '2rem',
    overflow: 'visible'
  },
  title: {
    marginLeft: '2rem',
    marginRight: '2rem',
    marginBottom: '-1.5rem',
    ...Typography.h3,
    maxWidth: '100%',
    color: COLORS.WHITE.default,
    textAlign: 'center'
  },
  videoContainer: {
    margin: '0',
    marginLeft: '1rem',
    marginRight: '1rem',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '0.5rem',
    background: COLORS.TRANSPARENT.default,
    zIndex: '10',
    '& video': {
      width: '100% !important',
      height: 'auto !important',
      display: 'block',
      margin: 'auto',
      borderRadius: '0.5rem'
    }
  }
}));

const MobileHero = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <div className={classes.logoContainer}>
        <img
          className={classes.logo}
          src={DefaultLogoInverted}
          alt="Relentlo Logo"
        />
      </div> */}
      <h3 className={classes.title}>Metaverse Experiences, Reimagined.</h3>
      <motion.div>
        <div
          style={{
            transform: 'translateY(4rem)'
          }}
          className={classes.videoContainer}
        >
          <video autoPlay muted loop playsInline id="carloop_splash">
            <source src="/static/home/carloop_splash.mp4" type="video/mp4" />
          </video>
        </div>
      </motion.div>
    </div>
  );
};

export default MobileHero;
