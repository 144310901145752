import { makeStyles } from '@material-ui/core';
import React from 'react';
import { COLORS } from 'src/webkit/foundation/Colors';
import 'aos/dist/aos.css';
import Typography from 'src/webkit/foundation/Typography';
import DefaultMediaCard from 'src/webkit/layouts/mediaCard/DefaultMediaCard';

const useStyles = makeStyles(() => ({
  ...Typography,
  root: {
    fontFamily: 'Elza',
    width: '100%',
    background: COLORS.BLACK[5],
    padding: '5rem',
    overflowX: 'hidden'
  },
  articlesContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: '2.5rem',
    marginTop: '4rem'
  }
}));

const News = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h3 className={classes.h3}>In the News</h3>
      <div className={classes.articlesContainer}>
        <DefaultMediaCard
          animated
          title="AdAge: EOS Brings Mariah Carey To Roblox"
          image="/static/home/news_1.png"
          link="https://adage.com/article/digital-marketing-ad-tech-news/eos-brings-mariah-carey-roblox/2455731"
        />
        <DefaultMediaCard
          animated
          data-aos="zoom-out"
          title="Yahoo Finance: Mariah Carey Comes To Roblox"
          image="/static/home/news_2.png"
          link="https://www.yahoo.com/now/mariah-carey-comes-roblox-mariah-011200192.html"
        />
        <DefaultMediaCard
          animated
          data-aos="zoom-out"
          title="Creative Ad-Tech Pioneer Relentlo Expands In-Game"
          image="/static/home/news_3.png"
          link="https://www.prweb.com/releases/changing_the_game_creative_ad_tech_pioneer_relentlo_expands_in_game_capabilities_into_the_metaverse/prweb18567472.htm"
        />
      </div>
    </div>
  );
};

export default News;
