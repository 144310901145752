module.exports = {
  apiKey: 'AIzaSyB6Pk0wQP6J6W7QeONvZEMnCp92-XqnnT4',
  authDomain: 'relentlo-690aa.firebaseapp.com',
  databaseURL: 'https://relentlo-690aa.firebaseio.com',
  projectId: 'relentlo-690aa',
  storageBucket: 'relentlo-690aa.appspot.com',
  messagingSenderId: '597111996743',
  appId: '1:597111996743:web:c40866e6e6f3062bfecc62',
  measurementId: 'G-FX57TM7071'
};
