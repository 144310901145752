/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  SESSION_EXPIRED,
  SET_TEMP_GAME_IMAGES,
  DELETE_GAME_IMAGE,
  SET_PARTNERED_GAMES,
  GAME_DATA_SUCCESS,
  MAP_DATA_SUCCESS,
  ADD_GAME,
  SET_USER_STUDIO,
  SET_ADMIN,
  FINISHED_LOADING
} from 'src/actions/accountActions';
import {
  ADMIN_DATA_REQUEST,
  ADMIN_DATA_SUCCESS,
  VERIFY_USER
} from 'src/actions/adminActions';

const initialState = {
  user: null,
  gameData: null,
  mapData: null,
  loading: false,
  sessionExpired: false
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
        draft.gameData = null;
        draft.sessionExpired = false;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.sessionExpired = false;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    /**
     * Can update a normal game user profile,
     * or updates a game within a studio
     */
    case UPDATE_PROFILE: {
      const { user, game } = action.payload;

      return produce(state, (draft) => {
        if (game) {
          draft.gameData[game] = user;
        } else {
          draft.user = user;
        }
      });
    }

    /**
     * For admin purposes only.
     * Verifies a game/studio.
     */
    case VERIFY_USER: {
      const { name, type } = action.payload;

      return produce(state, (draft) => {
        if (type === 'game') {
          draft.gameData[name]['verified'] = true;
        }
        if (type === 'studio') {
          draft.studioData[name]['verified'] = true;
        }
      });
    }

    case SESSION_EXPIRED: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.sessionExpired = true;
      });
    }

    case SET_TEMP_GAME_IMAGES: {
      const { tempGameImages, tempGameImageURLs } = action.payload;

      return produce(state, (draft) => {
        if (draft.user.tempGameImages) {
          draft.user.tempGameImages = draft.user.tempGameImages.concat(
            tempGameImages
          );
        } else {
          draft.user.tempGameImages = tempGameImageURLs;
        }
        draft.user.imageURLs = draft.user.imageURLs.concat(tempGameImageURLs);
      });
    }

    case DELETE_GAME_IMAGE: {
      const { index } = action.payload;

      return produce(state, (draft) => {
        draft.user.imageURLs = draft.user.imageURLs.filter((value, i, arr) => {
          return i !== index;
        });
      });
    }

    case SET_PARTNERED_GAMES: {
      const { partneredGames } = action.payload;

      return produce(state, (draft) => {
        draft.user.partneredGames = partneredGames;
      });
    }

    case ADD_GAME: {
      const game = action.payload.gameData;

      return produce(state, (draft) => {
        draft.gameData[game.name] = game;
      });
    }

    case GAME_DATA_SUCCESS: {
      const gameData = action.payload.data;
      return produce(state, (draft) => {
        draft.gameData = gameData;
      });
    }

    case MAP_DATA_SUCCESS: {
      const mapData = action.payload.data;
      return produce(state, (draft) => {
        draft.mapData = mapData;
      });
    }

    case SET_USER_STUDIO: {
      return produce(state, (draft) => {
        draft.user.type = 'studio';
        draft.loading = true;
      });
    }

    case SET_ADMIN: {
      return produce(state, (draft) => {
        draft.user.type = 'studio';
        draft.user.name = 'Relentlo Admin';
        draft.loading = true;
      });
    }

    case FINISHED_LOADING: {
      return produce(state, (draft) => {
        draft.loading = false;
      });
    }

    case ADMIN_DATA_SUCCESS: {
      const { gameData, brandData, studioData } = action.payload.allData;
      return produce(state, (draft) => {
        draft.gameData = gameData;
        draft.brandData = brandData;
        draft.studioData = studioData;
        draft.loading = false;
      });
    }

    case ADMIN_DATA_REQUEST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
