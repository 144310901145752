import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Menu,
  MenuItem,
  makeStyles,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { logout } from 'src/actions/accountActions';

const useStyles = makeStyles(() => ({
  avatar: {
    height: '100%',
    width: '100%'
  },
  popover: {
    padding: 0,
    width: 200,
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)'
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: 'white',
    '& li': {
      fontWeight: 400,
      paddingTop: 5,
      paddingBottom: 10,
      fontSize: '1rem'
    },
    '& a': {
      fontWeight: 400,
      paddingTop: 10,
      paddingBottom: 5,
      fontSize: '1rem'
    }
  },
  avatarButton: {
    height: 38,
    width: 38
  }
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Tooltip title="Your Account">
        <IconButton onClick={handleOpen} ref={ref}>
          <Avatar
            className={classes.avatarButton}
            src={account.user.avatarURL}
          />
        </IconButton>
      </Tooltip>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        MenuListProps={{ className: classes.list }}
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/app/game/settings">
          Account Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default Account;
