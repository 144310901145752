import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function GuestGuard({ children }) {
  const account = useSelector(state => state.account);

  if (account.user && account.user.verified) {
    if (account.user.type === "game") {
      return <Redirect to="/app" />;
    }
    if (account.user.type === 'brand') {
      return <Redirect to="/app/brand" />;
    }
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
