/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  POST_REQUEST,
  POST_SUCCESS,
  DELETE_POST,
  SET_POST_DATA,
  CLEAR_POST_DATA,
  CLEAR_POSTS
} from 'src/actions/postActions';

const initialState = {
  posts: [],
  allPosts: [],
  recommendedPosts: [],
  postData: null,
  loading: false
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case POST_SUCCESS: {
      let { posts, allPosts, recommendedPosts } = action.payload;
      if (!action.payload.posts) {
        posts = state.posts;
      }
      if (!action.payload.allPosts) {
        allPosts = state.allPosts;
      }
      if (!action.payload.recommendedPosts) {
        recommendedPosts = state.recommendedPosts;
      }

      return produce(state, (draft) => {
        draft.posts = posts;
        draft.allPosts = allPosts;
        draft.recommendedPosts = recommendedPosts;
        draft.loading = false;
      });
    }

    case DELETE_POST: {
      const { postID } = action.payload;

      return produce(state, (draft) => {
        draft.posts = draft.posts.filter((value, index, arr) => {
          return value.postID !== postID;
        });
        draft.allPosts = draft.allPosts.filter((value, index, arr) => {
          return value.postID !== postID;
        });
        draft.recommendedPosts = draft.recommendedPosts.filter(
          (value, index, arr) => {
            return value.postID !== postID;
          }
        );
      });
    }

    case SET_POST_DATA: {
      const { postData } = action.payload;

      return produce(state, (draft) => {
        draft.postData = { ...draft.postData, ...postData };
      });
    }

    case CLEAR_POST_DATA: {
      return produce(state, (draft) => {
        draft.postData = null;
      });
    }

    case CLEAR_POSTS: {
      return produce(state, (draft) => {
        draft.posts = [];
        draft.allPosts = [];
        draft.postData = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default postReducer;
