/* eslint-disable react/no-unescaped-entities */
/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import OutlinedPillButton from 'src/webkit/components/buttons/OutlinedPillButton';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';
import { BUTTON_VARIANTS } from 'src/webkit/constants/ButtonVariants';

const useStyles = makeStyles(() => ({
  ...Typography,
  root: {
    padding: '3rem 2rem',
    fontFamily: 'Elza',
    width: '100%',
    position: 'relative',
    overflowX: 'hidden'
  },
  programmaticWrapper: {
    marginBottom: '4rem'
  },
  title: {
    ...Typography.h5,
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  description: {
    ...Typography.p,
    marginBottom: '2rem'
  },
  video: {
    width: '100%',
    height: 'auto'
  }
}));

const MobileProducts = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.programmaticWrapper}>
        <OutlinedPillButton
          title="Programmatic"
          overrideStyles={{ background: COLORS.WHITE.default }}
          type="button"
          size={BUTTON_VARIANTS.SIZES.COMPACT}
          onClick={() => {}}
        />
        <h5 className={classes.title}>Dynamic in-game advertising</h5>
        <p className={classes.description}>
          Relentlo introduces native, creative, and dynamically updated
          advertising to video games and esports.
        </p>
        <video
          data-aos="zoom-out"
          className={classes.video}
          autoPlay
          muted
          loop
          playsInline
          id="product_vid"
        >
          <source src="/static/home/prog_vid_2.mp4" type="video/mp4" />
        </video>
      </div>
      <OutlinedPillButton
        title="Creator"
        overrideStyles={{ background: COLORS.WHITE.default }}
        type="button"
        size={BUTTON_VARIANTS.SIZES.COMPACT}
        onClick={() => {}}
      />
      <h5 className={classes.title}>
        Crafting shareable, immersive experiences
      </h5>
      <p className={classes.description}>
        Relentlo Creator is our hub connecting talented user generated content
        creators to interested brands, democratizing access to the world's most
        influential games.
      </p>
      <video
        data-aos="zoom-out"
        className={classes.video}
        autoPlay
        muted
        loop
        playsInline
        id="product_vid"
      >
        <source src="/static/home/creator_steve.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export default MobileProducts;
