import React, { useEffect, useState } from 'react';
import { NAVIGATION_VARIANTS } from 'src/webkit/constants/NavigationVariants';
import DefaultNavigation from 'src/webkit/layouts/navigation/DefaultNavigation';
import DefaultQuote from 'src/webkit/layouts/quote/DefaultQuote';
import AOS from 'aos';
import DefaultFooter from 'src/webkit/layouts/footer/DefaultFooter';
import MobileQuote from 'src/webkit/layouts/quote/MobileQuote';
import MobileFooter from 'src/webkit/layouts/footer/MobileFooter';
import MobileNavigation from 'src/webkit/layouts/navigation/MobileNavigation';
import Products from './components/desktop/products';
import Hero from './components/desktop/hero';
import Stats from './components/desktop/stats';
import Partner from './components/desktop/partner';
import News from './components/desktop/news';
import CTA from './components/desktop/cta';
import MobileHero from './components/mobile/mobileHero';
import MobileStats from './components/mobile/mobileStats';
import MobileProducts from './components/mobile/mobileProducts';
import MobilePartner from './components/mobile/mobilePartner';
import MobileNews from './components/mobile/mobileNews';
import MobileCta from './components/mobile/MobileCta';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

// eslint-disable-next-line import/prefer-default-export
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

function HomeView() {
  const { width } = useWindowDimensions();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      {width < 768 ? (
        <div>
          <MobileNavigation style={NAVIGATION_VARIANTS.STYLES.PRIMARY} />
          <MobileHero />
          <MobileStats />
          <MobileProducts />
          <MobilePartner />
          <MobileQuote
            quote="Relentlo's ads are what all video games need. During the campaign with Relentlo, Basketball Slam continued to look more like a real basketball court and our users' average gameplay actually went up even though they were engaging with Relentlo's ads more often than any other ad network we have used."
            author="Ben Banta"
            authorInfo="CEO, Ranida Games"
          />
          <MobileNews />
          <MobileCta />
          <MobileFooter />
        </div>
      ) : (
        <div>
          <DefaultNavigation style={NAVIGATION_VARIANTS.STYLES.PRIMARY} />
          <Hero />
          <Stats />
          <Products />
          <Partner />
          <DefaultQuote
            quote="Relentlo's ads are what all video games need. During the campaign with Relentlo, Basketball Slam continued to look more like a real basketball court and our users' average gameplay actually went up even though they were engaging with Relentlo's ads more often than any other ad network we have used."
            author="Ben Banta"
            authorInfo="CEO, Ranida Games"
          />
          <News />
          <CTA />
          <DefaultFooter style={NAVIGATION_VARIANTS.STYLES.PRIMARY} />
        </div>
      )}
    </div>
  );
}

export default HomeView;
