import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import {
  setUserData,
  logout,
  getUsers,
  getMaps
} from 'src/actions/accountActions';
import { getAdminData, getAllItems } from 'src/actions/adminActions';
import { getItems } from 'src/actions/itemActions';
import {
  getPosts,
  getAllPosts
  // getRecommendedPosts
} from 'src/actions/postActions';
import authService from 'src/services/authService';
import { getCampaigns } from 'src/actions/campaignActions';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        const user = await authService.loginInWithToken();

        await dispatch(setUserData(user));
        await dispatch(getAllPosts());
        await dispatch(getMaps());
        if (user.type === 'game') {
          await dispatch(getPosts());
        }
        if (user.type === 'brand' || user.type === 'studio') {
          await dispatch(getUsers());
        }
        if (user.type === 'brand') {
          await dispatch(getCampaigns());
          // await dispatch(getRecommendedPosts());
        }
        if (user.name === 'Relentlo Admin') {
          await dispatch(getAllItems());
          await dispatch(getAdminData());
        } else {
          await dispatch(getItems());
        }
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
