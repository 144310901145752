export default {
  h1: {
    fontWeight: 600,
    fontSize: '5.25rem',
    lineHeight: '6.25rem'
  },
  h2: {
    fontWeight: 600,
    fontSize: '3.75rem',
    lineHeight: '4rem'
  },
  h3: {
    fontWeight: 600,
    fontSize: '2.5rem',
    lineHeight: '3rem'
  },
  h4: {
    fontWeight: 600,
    fontSize: '2rem',
    lineHeight: '2.5rem'
  },
  h5: {
    fontWeight: 600,
    fontSize: '1.375rem',
    lineHeight: '2rem'
  },
  h6: {
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '1.5rem'
  },
  p: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.35rem'
  }
};
