import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import authService from 'src/services/authService';

function GameStudioAuthGuard({ key, children }) {
  const account = useSelector((state) => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }
  if (account.user.email === 'admin@relentlo.com') {
    return <Redirect to="/app/admin" />;
  }
  if (!account.user.verified) {
    return <Redirect to="/studiosignup" />;
  }
  if (account.user.type === 'brand') {
    return <Redirect to="/app/brand" />;
  }
  if (account.user.type === 'creator') {
    return <Redirect to="/app/creator" />;
  }
  if (account.user.type === 'game' && !authService.getStudioGame()) {
    return <Redirect to="/app" />;
  }
  if (key) {
    const currentGame = window.location.href
      .split('/')
      .pop()
      .replaceAll('%20', ' ');

    if (!(currentGame in account.gameData)) {
      return <Redirect to="/404" />;
    }
  }
  return children;
}

GameStudioAuthGuard.propTypes = {
  children: PropTypes.any
};

export default GameStudioAuthGuard;
