import React from 'react';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderLeft: `4px solid ${COLORS.PRIMARY.default}`,
    '& > p': {
      color: theme.palette.text.secondary,
      marginBottom: 0
    }
  }
}));

function Blockquote(props) {
  const classes = useStyles();

  return <blockquote className={classes.root} {...props} />;
}

export default Blockquote;
