const moment = require('moment');

/**
 * Need to add format string when making new moment object
 * moment(some date string) is deprecated
 *
 * See issue here: https://github.com/moment/moment/issues/1407
 * @param {String} date
 */
exports.momentf = (date) => {
  return moment.utc(date, 'YYYY-MM-DD UTC');
};

exports.momentf.utc = (date) => {
  return moment.utc(date, 'YYYY-MM-DD UTC');
};

/**
 * Returns the number of days between the start date and today
 * @param {Date} startDate
 * @return {number} numDays
 */
exports.numDaysBetween = (startDate) => {
  let today = moment();
  return today.diff(startDate, 'days');
};

/**
 * Returns today's date in ISO String format (UTC)
 * @return {String}
 */
const today = () => {
  let today = moment();
  return today.utc().format('YYYY-MM-DD UTC');
};
exports.today = today;

/**
 * Formats time into hours, minutes, and seconds
 * @param {Number} time
 */
exports.formatTimeString = (time) => {
  if (isNaN(time)) {
    return '0s';
  } else {
    const hrs = Math.floor(time / 3600);
    const min = Math.floor((time - hrs * 3600) / 60);
    const sec = time - hrs * 3600 - min * 60;
    if (hrs === 0 && min === 0) {
      return `${sec}s`;
    } else if (hrs === 0) {
      return `${min}m ${sec}s`;
    } else {
      return `${hrs}h ${min}m ${sec}s`;
    }
  }
};

/**
 * Formats array of UTC dates into dates of current timezone and shortens them
 * @param {Array} UTCdates
 */
exports.getShortenedDates = (UTCdates) => {
  let dates = [];
  UTCdates.forEach((UTCdate) => {
    let date = moment.utc(UTCdate, 'YYYY-MM-DD');
    dates.push(date.format('M/D'));
  });
  return dates;
};

/**
 * Formats string into a date of the form MM/DD/YY
 * @param {String} dateString
 */
exports.getFormattedDate = (dateString) => {
  return moment(dateString).format('M/D/YY');
};

/**
 * Formats moment into a date of the form MM/DD/YY
 * @param {moment} momentInput
 */
exports.getFormattedMoment = (momentInput) => {
  let date = moment(momentInput);
  return date.format('M/D/YY');
};
