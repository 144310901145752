/* eslint-disable react/no-unescaped-entities */
/* eslint-disable func-names */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import PillButton from 'src/webkit/components/buttons/PillButton';
import OutlinedPillButton from 'src/webkit/components/buttons/OutlinedPillButton';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';

const useStyles = makeStyles(() => ({
  ...Typography,
  root: {
    fontFamily: 'Elza',
    background: 'none',
    position: 'relative',
    padding: '5rem 0px'
  },
  fixed: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    height: '100%',
    width: 'calc((100vw - 160px) * 0.627451)'
  },
  fixedContainer: {
    position: 'sticky',
    top: '0px',
    right: '0px',
    width: '100%',
    height: '100vh'
  },
  fixedFlex: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  fixedRelative: {
    width: '100%',
    maxHeight: '100%',
    position: 'relative'
  },
  videoContainer: {
    margin: 'auto',
    height: '100vh',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '5rem'
  },
  video: {
    position: 'relative',
    maxHeight: '80% !important',
    maxWidth: '100% !important',
    width: 'auto !important',
    display: 'block',
    margin: 'auto',
    borderRadius: '1em'
  },
  buttons: {
    display: 'flex',
    gap: '15px',
    position: 'sticky',
    top: '5rem',
    marginLeft: '5rem',
    zIndex: '10'
  },
  scrollContainer: {
    padding: '5rem'
  },
  scrollContent: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '19fr 32fr',
    gridTemplateRows: 'repeat(1, minmax(auto, min(75vh, 100vh)))',
    WebkitBoxPack: 'center',
    justifyContent: 'center',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    gap: '5rem',
    margin: '0px auto',
    minHeight: 'min(100vh, 100vh)'
  },
  scrollBody: {
    scrollMargin: '32vh',
    gridRow: '1 / 2'
  },
  scrollTitle: {
    ...Typography.h3,
    marginBottom: '1rem'
  },
  scrollDescription: {
    ...Typography.p,
    maxWidth: '500px',
    marginBottom: '1rem'
  },
  programmaticLink: {
    ...Typography.p,
    textDecoration: 'none',
    color: COLORS.PRIMARY.default
  }
}));

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

const Products = () => {
  const classes = useStyles();
  const [current, setCurrent] = useState(0);
  const btn1Ref = useRef(null);
  const btn2Ref = useRef(null);
  const sec1ARef = useRef(null);
  const sec1BRef = useRef(null);
  const sec2ARef = useRef(null);
  const sec1AVisible = useOnScreen(sec1ARef);
  const sec1BVisible = useOnScreen(sec1BRef);
  const sec2AVisible = useOnScreen(sec2ARef);
  const vidSourceRef = useRef(null);
  const vidContainer = useRef(null);
  const vidRef = useRef(null);
  const [isScrolling, setScroll] = useState(false);

  const changeProduct = (visible, src, id) => {
    if (visible && !vidSourceRef.current.src.includes(src)) {
      setCurrent(id);
      vidContainer.current.style.transition = 'all 0.2s ease-out';
      vidContainer.current.style.opacity = 0;
      setTimeout(function() {
        vidSourceRef.current.src = src;
        vidRef.current.load();
      }, 250);
      vidRef.current.onloadeddata = function() {
        setTimeout(function() {
          vidContainer.current.style.opacity = 1;
          setScroll(false);
        }, 400);
      };
    }
  };

  useEffect(() => {
    if (!isScrolling) {
      changeProduct(
        sec1AVisible || sec1BVisible,
        '/static/home/prog_vid_2.mp4',
        0
      );
    }
  }, [sec1AVisible, sec1BVisible]);

  useEffect(() => {
    if (!isScrolling) {
      changeProduct(sec2AVisible, '/static/home/creator_steve.mp4', 1);
    }
  }, [sec2AVisible]);

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        {current === 0 ? (
          <PillButton
            title="Programmatic"
            ref={btn1Ref}
            type="button"
            onClick={() => {
              setScroll(true);
              changeProduct(true, '/static/home/prog_vid_2.mp4', 0);
              sec1ARef.current.scrollIntoView();
            }}
          />
        ) : (
          <OutlinedPillButton
            title="Programmatic"
            overrideStyles={{ background: COLORS.WHITE.default }}
            ref={btn1Ref}
            type="button"
            onClick={() => {
              setScroll(true);
              changeProduct(true, '/static/home/prog_vid_2.mp4', 0);
              sec1ARef.current.scrollIntoView();
            }}
          />
        )}
        {current === 1 ? (
          <PillButton
            title="Creator"
            ref={btn2Ref}
            type="button"
            onClick={() => {
              setScroll(true);
              changeProduct(true, '/static/home/creator_steve.mp4', 1);
              sec2ARef.current.scrollIntoView();
            }}
          />
        ) : (
          <OutlinedPillButton
            title="Creator"
            ref={btn2Ref}
            type="button"
            overrideStyles={{ background: COLORS.WHITE.default }}
            onClick={() => {
              setScroll(true);
              changeProduct(true, '/static/home/creator_steve.mp4', 1);
              sec2ARef.current.scrollIntoView();
            }}
          />
        )}
      </div>
      <div className={classes.fixed}>
        <div className={classes.fixedContainer}>
          <div ref={vidContainer} className={classes.fixedFlex}>
            <div className={classes.fixedRelative}>
              <div className={classes.videoContainer}>
                <video
                  ref={vidRef}
                  className={classes.video}
                  autoPlay
                  muted
                  loop
                  playsInline
                  id="product_vid"
                >
                  <source
                    ref={vidSourceRef}
                    src="/static/home/prog_vid_2.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className={classes.scrollContainer}>
        <div className={classes.scrollContent}>
          <div ref={sec1ARef} className={classes.scrollBody}>
            <h3 className={classes.scrollTitle}>Dynamic in-game advertising</h3>
            <p className={classes.scrollDescription}>
              Relentlo introduces native, creative, and dynamically updated
              advertising to video games and esports.
            </p>
            <a className={classes.programmaticLink} href="/programmatic">
              Learn More
            </a>
          </div>
        </div>
        <div className={classes.scrollContent}>
          <div ref={sec1BRef} className={classes.scrollBody}>
            <h3 className={classes.scrollTitle}>37M Users</h3>
            <p className={classes.scrollDescription}>
              Relentlo Programmatic offers access to millions of users and a
              wide range of demographics.
            </p>
          </div>
        </div>
      </section>
      <section className={classes.scrollContainer}>
        <div className={classes.scrollContent}>
          <div ref={sec2ARef} className={classes.scrollBody}>
            <h3 className={classes.scrollTitle}>
              Crafting shareable, immersive experiences
            </h3>
            <p className={classes.scrollDescription}>
              Relentlo Creator is our hub connecting talented user generated
              content creators to interested brands, democratizing access to the
              world's most influential games.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Products;
