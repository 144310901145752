/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';

const useStyles = makeStyles(() => ({
  ...Typography,
  root: {
    fontFamily: 'Elza',
    width: '100%'
  },
  imgContainer: {
    width: '100%',
    height: '12.5rem',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      scale: '1'
    }
  },
  textContainer: {
    marginTop: '1.5rem',
    width: '100%',
    '& h6': {
      marginBottom: '0.5rem'
    }
  },
  readMore: {
    ...Typography.p,
    textDecoration: 'none',
    color: COLORS.PRIMARY.default
  }
}));

function MobileMediaCard(props) {
  const classes = useStyles();
  const { title, image, link, animated = false } = props;

  return (
    <div className={classes.root}>
      <div
        data-aos={animated ? 'zoom-out' : 'none'}
        className={classes.imgContainer}
      >
        <img src={image} alt="Article" />
      </div>
      <div className={classes.textContainer}>
        <h6 className={classes.h6}>{title}</h6>
        <a href={link} target="_blank" className={classes.readMore}>
          Read More
        </a>
      </div>
    </div>
  );
}

MobileMediaCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  animated: PropTypes.bool
};

export default MobileMediaCard;
