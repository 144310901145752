/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';

const useStyles = makeStyles(() => ({
  ...Typography,
  root: {
    cursor: 'none',
    fontFamily: 'Elza',
    width: '100%',
    '&:hover': {
      '& $imgContainer': {
        scale: '1.05',
        transition: 'all .2s ease-in-out !important'
      },
      '& $cursor': {
        visibility: 'visible',
        opacity: '1'
      }
    },
    '& $cursor': {
      visibility: 'hidden',
      opacity: '0'
    }
  },
  imgContainer: {
    width: '100%',
    height: '16rem',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    transition: 'all .2s ease-in-out !important',
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      scale: '1'
    }
  },
  textContainer: {
    marginTop: '2rem',
    width: '100%',
    '& h5': {
      marginBottom: '0.5rem'
    }
  },
  readMore: {
    ...Typography.p,
    color: COLORS.BLACK[60]
  },
  cursor: {
    display: 'flex',
    // transition: 'visibility 0s, opacity 0.2s linear',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
    visibility: 'hidden',
    zIndex: '20',
    width: '3rem',
    height: '3rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '999px',
    background: COLORS.PRIMARY.default,
    color: COLORS.WHITE.default,
    opacity: '0'
  },
  cursorImg: {
    width: '1rem',
    height: 'auto'
  }
}));

function DefaultMediaCard(props) {
  const classes = useStyles();
  const { title, image, link, animated = false } = props;
  const cursor = useRef(null);

  const onMouseMove = (e) => {
    if (cursor.current) {
      cursor.current.style.top = `${e.pageY}px`;
      cursor.current.style.left = `${e.pageX}px`;
    }
  };

  const onMouseDown = () => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
  }, [cursor]);

  return (
    <div className={classes.root} onMouseDown={onMouseDown}>
      <div ref={cursor} className={classes.cursor}>
        <img
          className={classes.cursorImg}
          src="/static/arrow_white.png"
          alt="arrow"
        />
      </div>
      <div
        data-aos={animated ? 'zoom-out' : 'none'}
        className={classes.imgContainer}
      >
        <img src={image} alt="Article" />
      </div>
      <div className={classes.textContainer}>
        <h5 className={classes.h5}>{title}</h5>
        <p className={classes.readMore}>Click to Read More</p>
      </div>
    </div>
  );
}

DefaultMediaCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  animated: PropTypes.bool
};

export default DefaultMediaCard;
