/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ITEM_REQUEST,
  ITEM_SUCCESS,
  SET_ITEM,
  ADD_ITEM,
  DELETE_ITEM,
  CLEAR_ITEMS,
  DELETE_POST_FROM_ITEM
} from 'src/actions/itemActions';

const initialState = {
  items: null,
  item: null,
  loading: true
};

const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case ITEM_REQUEST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case ITEM_SUCCESS: {
      const { items } = action.payload;

      return produce(state, (draft) => {
        draft.items = items;
        draft.loading = false;
      });
    }

    case SET_ITEM: {
      const { item } = action.payload;

      return produce(state, (draft) => {
        draft.item = item;
      });
    }

    case ADD_ITEM: {
      const { itemName, itemData, defaultItem } = action.payload;

      return produce(state, (draft) => {
        if (!draft.items) {
          draft.items = {};
          draft.item = itemName;
        }
        draft.items[itemName] = itemData;
        draft.item = defaultItem;
      });
    }

    case DELETE_ITEM: {
      const { item, defaultItem } = action.payload;

      return produce(state, (draft) => {
        delete draft.items[item];
        if (Object.keys(draft.items).length === 0) {
          draft.items = null;
        }
        draft.item = defaultItem;
      });
    }

    case CLEAR_ITEMS: {
      return produce(state, (draft) => {
        draft.items = null;
        draft.item = null;
        draft.loading = true;
      });
    }

    case DELETE_POST_FROM_ITEM: {
      const { postID } = action.payload;

      return produce(state, (draft) => {
        Object.keys(draft.items).forEach((item) => {
          if (draft.items[item].postID === postID) {
            draft.items[item].postID = '';
          }
        });
      });
    }

    default: {
      return state;
    }
  }
};

export default itemReducer;
