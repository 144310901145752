import { makeStyles } from '@material-ui/core';
import React from 'react';
import { COLORS } from 'src/webkit/foundation/Colors';
import DefaultInfoCard from 'src/webkit/layouts/infoCard/DefaultInfoCard';
import DefaultStatsDisplay from 'src/webkit/layouts/statsDisplay/DefaultStatsDisplay';
import 'aos/dist/aos.css';
import ExtensionIcon from '@mui/icons-material/Extension';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Elza',
    width: '100%',
    background: COLORS.BLACK[5],
    paddingLeft: '5rem',
    paddingRight: '5rem',
    paddingTop: '10rem',
    paddingBottom: '5rem',
    overflowX: 'hidden'
  },
  infoContainer: {
    width: '100%',
    marginTop: '2.5rem',
    display: 'flex',
    alignItems: 'stretch',
    gap: '2.5rem',
    height: '100%'
  },
  infoCardWrapper: {
    flex: '1'
    // height: '100%'
  }
}));

const Stats = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DefaultStatsDisplay
        animated
        title="Creating Innovative Experiences that Scale"
        data={[
          { value: '1.2B+', title: 'Downloads' },
          { value: '30+', title: 'Unique Games' },
          { value: '200M+', title: 'Monthly Active Users' }
        ]}
      />
      <div className={classes.infoContainer}>
        <DefaultInfoCard
          animated
          title="Use Our Plugin"
          description="Our lightweight and easy-to-implement plugin adds branded experiences to your game without disrupting players and increasing engagement."
          icon={
            <ExtensionIcon
              style={{ fill: COLORS.WHITE.default, fontSize: '48px' }}
            />
          }
        />
        <DefaultInfoCard
          animated
          title="Work With Top Creators"
          description="Fortnite, Minecraft, and Roblox content creators can now monetize their work. Spend more time doing what you love, and get paid for it."
          icon={
            <LightbulbIcon
              style={{ fill: COLORS.WHITE.default, fontSize: '48px' }}
            />
          }
        />

        {/* <div className={classes.infoCardWrapper}>
          <DefaultInfoCard
            animated
            title="Use Our Plugin"
            description="Our lightweight and easy-to-implement plugin adds branded experiences to your game without disrupting players and increasing engagement."
            icon={
              <ExtensionIcon
                style={{ fill: COLORS.WHITE.default, fontSize: '48px' }}
              />
            }
            justifyContent="center"
          />
        </div> */}
        {/* <div className={classes.infoCardWrapper}>
          <DefaultInfoCard
            animated
            title="Work With Top Creators"
            description="Fortnite, Minecraft, and Roblox content creators can now monetize their work. Spend more time doing what you love, and get paid for it."
            icon={
              <LightbulbIcon
                style={{ fill: COLORS.WHITE.default, fontSize: '48px' }}
              />
            }
            justifyContent="center"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Stats;
