import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { COLORS } from 'src/webkit/foundation/Colors';
import { motion } from 'framer-motion';
import { max, min } from 'lodash';
import Typography from 'src/webkit/foundation/Typography';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Elza',
    width: '100%',
    background: COLORS.PRIMARY.default,
    paddingTop: '2.5rem',
    overflow: 'visible'
  },
  title: {
    marginLeft: '5rem',
    marginRight: '5rem',
    marginBottom: '-2.5rem',
    ...Typography.h1,
    fontWeight: '500',
    maxWidth: '100%',
    color: COLORS.WHITE.default,
    textAlign: 'center'
  },
  videoContainer: {
    margin: '0',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '0.5rem',
    background: COLORS.TRANSPARENT.default,
    zIndex: '10',
    '& video': {
      width: '100% !important',
      height: 'auto !important',
      display: 'block',
      margin: 'auto',
      borderRadius: '0.5rem'
    }
  }
}));

function getScale(scroll, sectionRef) {
  if (sectionRef.current) {
    return min([1 - 160 / sectionRef.current.offsetWidth, scroll ** 2 + 0.8]);
  }
  return 0.8;
}

const Hero = () => {
  const classes = useStyles();
  const [scroll, setScroll] = useState(0);
  const videoRef = useRef(null);
  const sectionRef = useRef(null);

  const updateScroll = () => {
    if (sectionRef.current) {
      const winScroll = window.pageYOffset;
      const height = sectionRef.current.offsetHeight;
      const scrolled = winScroll / height;
      setScroll(scrolled);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScroll);
  }, [videoRef]);

  return (
    <div ref={sectionRef} className={classes.root}>
      <h1 className={classes.title}>Metaverse Experiences, Reimagined.</h1>
      <motion.div
        ref={videoRef}
        style={{
          zIndex: '10',
          position: 'relative',
          y: min([-50 + 100 * scroll, 0]),
          scale: getScale(scroll, sectionRef)
        }}
      >
        <div
          style={{
            transform: `translateY(${min([10, 10 * scroll + 6])}rem)`
          }}
          className={classes.videoContainer}
        >
          <video autoPlay muted loop playsInline id="carloop_splash">
            <source src="/static/home/carloop_splash.mp4" type="video/mp4" />
          </video>
        </div>
      </motion.div>
    </div>
  );
};

export default Hero;
