import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { NAVIGATION_VARIANTS } from 'src/webkit/constants/NavigationVariants';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';
import { LinkedIn, Mail, Twitter } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import DefaultLogoInverted from '../../foundation/logos/logo-icon-inverted.svg';
import DefaultLogoPrimary from '../../foundation/logos/logo-icon-primary.svg';

const getBackground = (style) => {
  switch (style) {
    case NAVIGATION_VARIANTS.STYLES.PRIMARY:
      return COLORS.PRIMARY.default;
    case NAVIGATION_VARIANTS.STYLES.SECONDARY:
      return COLORS.WHITE.default;
    case NAVIGATION_VARIANTS.STYLES.DARK:
      return COLORS.BLACK[100];
    default:
      return COLORS.PRIMARY.default;
  }
};

const getColor = (style) => {
  switch (style) {
    case NAVIGATION_VARIANTS.STYLES.PRIMARY:
      return COLORS.WHITE.default;
    case NAVIGATION_VARIANTS.STYLES.SECONDARY:
      return COLORS.PRIMARY.default;
    case NAVIGATION_VARIANTS.STYLES.DARK:
      return COLORS.WHITE.default;
    default:
      return COLORS.WHITE.default;
  }
};

const getLogo = (style) => {
  switch (style) {
    case NAVIGATION_VARIANTS.STYLES.PRIMARY:
      return DefaultLogoInverted;
    case NAVIGATION_VARIANTS.STYLES.SECONDARY:
      return DefaultLogoPrimary;
    case NAVIGATION_VARIANTS.STYLES.DARK:
      return DefaultLogoInverted;
    default:
      return DefaultLogoInverted;
  }
};

const useStyles = (props) =>
  makeStyles(() => ({
    ...Typography,
    root: {
      fontFamily: 'Elza',
      width: '100%',
      background: props.bgColor,
      padding: '2rem 5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      overflowX: 'hidden'
    },
    leftContainer: {
      height: '1.75rem',
      width: 'fit-content',
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      '& img': {
        height: '100%',
        width: 'auto'
      },
      '& p': {
        color: COLORS.WHITE.default
      }
    },
    rightContainer: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      justifyContent: 'end'
    }
  }));

function DefaultFooter({
  overrideStyles,
  style = NAVIGATION_VARIANTS.STYLES.PRIMARY
}) {
  const bgColor = getBackground(style);
  const textColor = getColor(style);
  const logo = getLogo(style);

  const classes = useStyles({ bgColor, textColor })();

  return (
    <div className={classes.root} style={overrideStyles}>
      <div className={classes.leftContainer}>
        <img src={logo} alt="Relentlo Logo" />
        <p className={classes.p}>© 2022 Relentlo</p>
      </div>
      <div className={classes.rightContainer}>
        <IconButton
          onClick={() => {
            window.open(
              'https://www.linkedin.com/company/relentlo/',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        >
          <LinkedIn sx={{ fontSize: '2rem', color: COLORS.WHITE.default }} />
        </IconButton>
        <IconButton
          onClick={() => {
            window.open(
              'https://twitter.com/relentlo_inc',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        >
          <Twitter sx={{ fontSize: '2rem', color: COLORS.WHITE.default }} />
        </IconButton>
        <IconButton
          onClick={() => {
            window.open(
              'mailto:info@relentlo.com',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        >
          <Mail sx={{ fontSize: '2rem', color: COLORS.WHITE.default }} />
        </IconButton>
      </div>
    </div>
  );
}

DefaultFooter.propTypes = {
  overrideStyles: PropTypes.any,
  style: PropTypes.number
};

export default DefaultFooter;
