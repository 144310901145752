import axios from 'src/utils/axios';
import { SESSION_EXPIRED } from 'src/actions/accountActions';
import { ITEM_REQUEST, ITEM_SUCCESS, setItem } from 'src/actions/itemActions';
import moment from 'moment';
import { formatItemData, formatManyGames } from 'src/utils/formatData';

export const ADMIN_DATA_REQUEST = '@account/admin-data-request';
export const ADMIN_DATA_SUCCESS = '@account/admin-data-success';
export const VERIFY_USER = '@account/admin-update-user';

export function getAdminData(history) {
  return async (dispatch) => {
    dispatch({ type: ADMIN_DATA_REQUEST });
    try {
      let data = await axios.get('/getAllUsers', {
        params: {
          type: 'game'
        }
      });
      const gameData = data.data;
      formatManyGames(gameData, true);
      Object.keys(gameData).forEach((key) => {
        if (!gameData[key].studioInfo) {
          gameData[key].studioInfo = {
            current: true,
            updatedAt: moment(),
            earned: 0
          };
        }
      });
      data = await axios.get('/getAllUsers', {
        params: {
          type: 'brand'
        }
      });
      const brandData = data.data;
      formatManyGames(brandData, true);
      data = await axios.get('/getAllUsers', {
        params: {
          type: 'studio'
        }
      });
      const studioData = data.data;
      formatManyGames(studioData, true);
      const allData = { gameData, brandData, studioData };
      dispatch({ type: ADMIN_DATA_SUCCESS, payload: { allData } });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        if (history) {
          history.push('/login');
        }
      }
      throw error;
    }
  };
}

export function getAllItems() {
  return async (dispatch) => {
    dispatch({ type: ITEM_REQUEST });
    try {
      const itemData = await axios.get('/getAllItems');
      dispatch(setItem(Object.keys(itemData.data)[0]));
      Object.keys(itemData.data).forEach((item) => {
        const oneItemData = itemData.data[item];
        formatItemData(oneItemData, true);
      });
      dispatch({ type: ITEM_SUCCESS, payload: { items: itemData.data } });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
      }
      throw error;
    }
  };
}

/**
 * Verifies user - game or studio
 * @param docID This is the document ID corresponding to that user
 * @param name This is the name of the name
 * @param type This is either game or studio
 * @param {*} history 
 * e.g. { verified: true }
 * @returns 
 */
export function verifyUser(history, docID, name, type) {
  return async (dispatch) => {
    try {
      await axios.post(`/${docID}/updateUser`, {
        verified: true
      });
      dispatch({ type: VERIFY_USER, payload: { name: name, type: type } });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function uploadGameAssetWithBrand(
  history,
  asset,
  itemName,
  gameName,
  brandName
) {
  return (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('asset', asset);
      formData.append('item', itemName);
      formData.append('game', gameName);
      formData.append('brand', brandName);

      axios.post('/uploadGameAssetWithBrand', formData);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function addPartnership(history, partnershipData) {
  return async (dispatch) => {
    try {
      await axios.post('/addPartnership', partnershipData);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function getAssetItems(history, gameName, folderName) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/${gameName}/${folderName}/getAssetItems`,
        {
          headers: { 'Access-Control-Allow-Origin': '*' }
        }
      );
      return response.data.files;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}
