import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { COLORS } from 'src/webkit/foundation/Colors';
import { BUTTON_VARIANTS } from '../../constants/ButtonVariants';

const getBackground = (style) => {
  switch (style) {
    case BUTTON_VARIANTS.STYLES.PRIMARY:
      return {
        default: COLORS.PRIMARY.default,
        hover: COLORS.PRIMARY[100],
        disabled: COLORS.PRIMARY[40]
      };
    case BUTTON_VARIANTS.STYLES.SECONDARY:
      return {
        default: COLORS.BLACK.default,
        hover: COLORS.BLACK[100],
        disabled: COLORS.BLACK[40]
      };
    case BUTTON_VARIANTS.STYLES.TERTIARY:
      return {
        default: COLORS.BLACK[5],
        hover: COLORS.BLACK[10],
        disabled: COLORS.BLACK[5]
      };
    case BUTTON_VARIANTS.STYLES.INVERTED:
      return {
        default: COLORS.WHITE.default,
        hover: COLORS.PRIMARY[5],
        disabled: COLORS.WHITE.default
      };
    default:
      return {
        default: COLORS.PRIMARY.default,
        hover: COLORS.PRIMARY[100],
        disabled: COLORS.PRIMARY[40]
      };
  }
};

const getColor = (style) => {
  switch (style) {
    case BUTTON_VARIANTS.STYLES.PRIMARY:
      return {
        default: COLORS.WHITE.default,
        hover: COLORS.WHITE.default,
        disabled: COLORS.WHITE.default
      };
    case BUTTON_VARIANTS.STYLES.SECONDARY:
      return {
        default: COLORS.WHITE.default,
        hover: COLORS.WHITE.default,
        disabled: COLORS.WHITE.default
      };
    case BUTTON_VARIANTS.STYLES.TERTIARY:
      return {
        default: COLORS.BLACK.default,
        hover: COLORS.BLACK.default,
        disabled: COLORS.BLACK[40]
      };
    case BUTTON_VARIANTS.STYLES.INVERTED:
      return {
        default: COLORS.PRIMARY.default,
        hover: COLORS.PRIMARY.default,
        disabled: COLORS.PRIMARY[40]
      };
    default:
      return {
        default: COLORS.WHITE.default,
        hover: COLORS.WHITE.default,
        disabled: COLORS.WHITE.default
      };
  }
};

const getFont = (size) => {
  switch (size) {
    case BUTTON_VARIANTS.SIZES.DEFAULT:
      return '0.875rem';
    case BUTTON_VARIANTS.SIZES.COMPACT:
      return '0.8125rem';
    default:
      return '1rem';
  }
};

const getPadding = (size) => {
  switch (size) {
    case BUTTON_VARIANTS.SIZES.DEFAULT:
      return '0.75rem 1.75rem';
    case BUTTON_VARIANTS.SIZES.COMPACT:
      return '0.625rem 0.875rem';
    default:
      return '0.75rem 1.75rem';
  }
};

function PillButton({
  title,
  onClick,
  overrideStyles,
  style = BUTTON_VARIANTS.STYLES.PRIMARY,
  size = BUTTON_VARIANTS.SIZES.default,
  disabled = false,
  type = '',
  passedRef
}) {
  const bgColor = getBackground(style);
  const textColor = getColor(style);
  const fontSize = getFont(size);
  const padding = getPadding(size);

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={overrideStyles}
      variant="contained"
      disableElevation
      size="medium"
      ref={passedRef}
      sx={{
        width: 'fit-content',
        display: 'inline',
        fontFamily: 'Elza',
        fontWeight: '400',
        lineHeight: '16px',
        fontSize,
        padding,
        textTransform: 'none',
        backgroundColor: bgColor.default,
        color: textColor.default,
        borderRadius: '999px',
        '&:hover': {
          backgroundColor: bgColor.hover,
          color: textColor.hover
        },
        '&:disabled': {
          backgroundColor: bgColor.disabled,
          color: textColor.disabled
        }
      }}
    >
      {title}
    </Button>
  );
}

PillButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  overrideStyles: PropTypes.any,
  style: PropTypes.number,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  passedRef: PropTypes.any
};

export default PillButton;
