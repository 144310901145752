import { makeStyles } from '@material-ui/core';
import React from 'react';
import SideBar from './SideBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Elza'
  },
  wrapper: {
    display: 'flex',
    flex: '1 auto',
    height: '100%',
    position: 'relative'
  },
  content: {
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  nav: {
    background: '#F5F6F9',
    position: 'relative'
  }
}));

function IQLayout({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.nav}>
          <SideBar />
        </div>
        <div className={classes.content}>
          <TopBar />
          {children}
        </div>
      </div>
    </div>
  );
}

export default IQLayout;
