import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';

const useStyles = makeStyles(() => ({
  ...Typography,
  root: {
    fontFamily: 'Elza',
    width: '100%',
    padding: '3rem 2rem',
    background: COLORS.BLUE[5],
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  container: {
    width: '100%'
  },
  imgContainer: {
    height: '2.5rem'
  },
  topImg: {
    height: '100%',
    width: 'auto'
  },
  bottomImg: {
    height: '100%',
    width: 'auto',
    transform: 'rotate(180deg)'
  },
  quoteContainer: {
    width: '100%',
    padding: '0',
    marginTop: '2rem',
    marginBottom: '2rem'
  },
  bottomContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
    // paddingLeft: '2.5rem'
  },
  quote: {
    ...Typography.h6,
    fontWeight: '400'
  },
  author: {
    ...Typography.p,
    fontWeight: 600
  },
  authorInfo: {
    fontSize: '0.8rem',
    fontWeight: 400
  }
}));

function MobileQuote(props) {
  const classes = useStyles();
  const { quote, author, authorInfo } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.imgContainer}>
          <img
            data-aos="fade-down"
            className={classes.topImg}
            src="/static/arrows_down.png"
            alt="arrows"
          />
        </div>
        <div data-aos="fade-right" className={classes.quoteContainer}>
          <h6 className={classes.quote}>{quote}</h6>
        </div>
        <div className={classes.bottomContainer}>
          <div data-aos="fade-right" className={classes.authorContainer}>
            <p className={classes.author}>{author}</p>
            <p className={classes.authorInfo}>{authorInfo}</p>
          </div>
          <div className={classes.imgContainer}>
            <img
              data-aos="fade-up"
              className={classes.bottomImg}
              src="/static/arrows_down.png"
              alt="arrows"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

MobileQuote.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  authorInfo: PropTypes.string
};

export default MobileQuote;
