import axios from 'src/utils/axios';
import { SESSION_EXPIRED } from 'src/actions/accountActions';
import {
  getPosts,
  // getRecommendedPosts,
  getAllPosts
} from 'src/actions/postActions';
import { formatItemData } from 'src/utils/formatData';

export const ITEM_REQUEST = '@item/item-request';
export const ITEM_SUCCESS = '@item/item-success';
export const SET_ITEM = '@item/set-item';
export const ADD_ITEM = '@item/add-item';
export const DELETE_ITEM = '@item/delete-item';
export const CLEAR_ITEMS = '@item/clear-items';
export const DELETE_POST_FROM_ITEM = '@item/delete-post-from-item';

export function getItem(item, history) {
  return async (dispatch) => {
    try {
      const itemData = await axios.get(`/${item.item}/getData`, {
        params: {
          game: item.game
        }
      });
      formatItemData(itemData.data);
      return itemData.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        if (history) {
          history.push('/login');
        }
      }
      throw error;
    }
  };
}

export function setItem(item) {
  return (dispatch) => {
    dispatch({
      type: SET_ITEM,
      payload: {
        item
      }
    });
  };
}

// If you change this function, make sure to change
// getAllItems too
export function getItems(history) {
  return async (dispatch) => {
    dispatch({ type: ITEM_REQUEST });
    try {
      const itemData = await axios.get(`/getItems`);
      dispatch(setItem(Object.keys(itemData.data)[0]));
      Object.values(itemData.data).forEach((oneItemData) => {
        formatItemData(oneItemData);
      });
      dispatch({ type: ITEM_SUCCESS, payload: { items: itemData.data } });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        if (history) {
          history.push('/login');
        }
      }
      throw error;
    }
  };
}

export function addItem(history, item) {
  return async (dispatch) => {
    try {
      await axios.post('/addItem', item);
      const itemData = await dispatch(getItem(item, history));
      formatItemData(itemData);

      dispatch({
        type: ADD_ITEM,
        payload: {
          itemName: item.item,
          itemData,
          defaultItem: item.item
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function deleteItem(history, item, defaultItem = null) {
  return async (dispatch) => {
    try {
      await axios.post('/deleteItem', item);
      dispatch({
        type: DELETE_ITEM,
        payload: {
          item: item.item,
          defaultItem
        }
      });
      // dispatch(getItems(history));
      if (item.game === '') {
        dispatch(getPosts(history));
        dispatch(getAllPosts(history));
        // dispatch(getRecommendedPosts(history));
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}
