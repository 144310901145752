import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import authService from 'src/services/authService';

function AdminAuthGuard({ children }) {
  const account = useSelector((state) => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }
  if (account.user.type === 'brand' && !authService.getUser()) {
    return <Redirect to="/app/brand" />;
  }
  if (account.user.type === 'game' && !authService.getUser()) {
    return <Redirect to="/app" />;
  }
  if (
    account.user.type === 'studio' &&
    account.user.name !== 'Relentlo Admin'
  ) {
    return <Redirect to="/app/studio" />;
  }
  return children;
}

AdminAuthGuard.propTypes = {
  children: PropTypes.any
};

export default AdminAuthGuard;
