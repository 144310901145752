import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { NAVIGATION_VARIANTS } from 'src/webkit/constants/NavigationVariants';
import { COLORS } from 'src/webkit/foundation/Colors';
import { useHistory } from 'react-router';
import OutlinedPillButton from 'src/webkit/components/buttons/OutlinedPillButton';
import { BUTTON_VARIANTS } from 'src/webkit/constants/ButtonVariants';
import Typography from 'src/webkit/foundation/Typography';
import IconLogoInverted from '../../foundation/logos/logo-icon-inverted.svg';
import IconLogoPrimary from '../../foundation/logos/logo-icon-primary.svg';
import DefaultLogoInverted from '../../foundation/logos/logo-default-inverted.svg';
import DefaultLogoPrimary from '../../foundation/logos/logo-default-primary.svg';

const getBackground = (style) => {
  switch (style) {
    case NAVIGATION_VARIANTS.STYLES.PRIMARY:
      return COLORS.PRIMARY.default;
    case NAVIGATION_VARIANTS.STYLES.SECONDARY:
      return COLORS.WHITE.default;
    case NAVIGATION_VARIANTS.STYLES.TERTIARY:
      return COLORS.BLACK[5];
    case NAVIGATION_VARIANTS.STYLES.DARK:
      return COLORS.BLACK[100];
    default:
      return COLORS.PRIMARY.default;
  }
};

const getColor = (style) => {
  switch (style) {
    case NAVIGATION_VARIANTS.STYLES.PRIMARY:
      return COLORS.WHITE.default;
    case NAVIGATION_VARIANTS.STYLES.SECONDARY:
      return COLORS.PRIMARY.default;
    case NAVIGATION_VARIANTS.STYLES.TERTIARY:
      return COLORS.PRIMARY.default;
    case NAVIGATION_VARIANTS.STYLES.DARK:
      return COLORS.WHITE.default;
    default:
      return COLORS.WHITE.default;
  }
};

const getLogo = (style) => {
  switch (style) {
    case NAVIGATION_VARIANTS.STYLES.PRIMARY:
      return DefaultLogoInverted;
    case NAVIGATION_VARIANTS.STYLES.SECONDARY:
      return DefaultLogoPrimary;
    case NAVIGATION_VARIANTS.STYLES.TERTIARY:
      return DefaultLogoPrimary;
    case NAVIGATION_VARIANTS.STYLES.DARK:
      return DefaultLogoInverted;
    default:
      return DefaultLogoInverted;
  }
};

const getIcon = (style) => {
  switch (style) {
    case NAVIGATION_VARIANTS.STYLES.PRIMARY:
      return IconLogoInverted;
    case NAVIGATION_VARIANTS.STYLES.SECONDARY:
      return IconLogoPrimary;
    case NAVIGATION_VARIANTS.STYLES.TERTIARY:
      return IconLogoPrimary;
    case NAVIGATION_VARIANTS.STYLES.DARK:
      return IconLogoInverted;
    default:
      return IconLogoInverted;
  }
};

const useStyles = (props) =>
  makeStyles(() => ({
    root: {
      fontFamily: 'Elza',
      width: '100%',
      background: props.bgColor,
      padding: '2rem 2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    leftContainer: {
      height: '1.25rem',
      width: 'fit-content',
      '& img': {
        height: '100%',
        width: 'auto'
      }
    },
    rightContainer: {
      display: 'flex',
      gap: '2rem',
      alignItems: 'center',
      justifyContent: 'end'
    },
    loginButton: {
      ...Typography.p,
      background: 'none',
      color: props.textColor,
      border: 'none',
      outline: 'none',
      cursor: 'pointer'
    }
  }));

function MobileNavigation({
  overrideStyles,
  style = NAVIGATION_VARIANTS.STYLES.PRIMARY,
  useLogo = true
}) {
  const history = useHistory();
  const bgColor = getBackground(style);
  const textColor = getColor(style);
  const logo = useLogo ? getLogo(style) : getIcon(style);

  const classes = useStyles({ bgColor, textColor })();

  const redirectHome = () => {
    history.push('/');
  };

  const redirectAbout = () => {
    history.push('/about');
  };

  return (
    <div className={classes.root} style={overrideStyles}>
      <div className={classes.leftContainer}>
        <img src={logo} alt="Relentlo Logo" />
      </div>
      <div className={classes.rightContainer}>
        <button
          className={classes.loginButton}
          type="button"
          onClick={redirectHome}
        >
          Home
        </button>
        <button
          className={classes.loginButton}
          type="button"
          onClick={redirectAbout}
        >
          About
        </button>
      </div>
    </div>
  );
}

MobileNavigation.propTypes = {
  overrideStyles: PropTypes.any,
  style: PropTypes.number,
  useLogo: PropTypes.bool
};

export default MobileNavigation;
