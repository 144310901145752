import axios from 'src/utils/axios';
import { SESSION_EXPIRED } from 'src/actions/accountActions';

export const CAMPAIGN_REQUEST = '@campaign/campaign-request';
export const ADD_CAMPAIGN = '@campaign/add-campaign';
export const CAMPAIGN_SUCCESS = '@campaign/campaign-success';
export const DELETE_CAMPAIGN = '@campaign/delete-campaign';
export const SET_CAMPAIGN_DATA = '@campaign/set-campaign-data';
export const CLEAR_CAMPAIGN_DATA = '@campaign/clear-campaign-data';
export const CLEAR_CAMPAIGNS = '@campaign/clear-campaigns';

export function getCampaigns(history) {
  return async (dispatch) => {
    try {
      dispatch({ type: CAMPAIGN_REQUEST });
      const campaigns = await axios.get('/getCampaigns');
      dispatch({
        type: CAMPAIGN_SUCCESS,
        payload: {
          campaigns: campaigns.data
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        if (history) {
          history.push('/login');
        }
      }
      throw error;
    }
  };
}

export function getAllCampaigns(history) {
  return async (dispatch) => {
    try {
      dispatch({ type: CAMPAIGN_REQUEST });
      const allCampaigns = await axios.get('/getAllCampaigns');
      dispatch({
        type: CAMPAIGN_SUCCESS,
        payload: {
          allCampaigns: allCampaigns.data
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        if (history) {
          history.push('/login');
        }
      }
      throw error;
    }
  };
}

export function setCampaignData(campaignData) {
  return (dispatch) => {
    dispatch({
      type: SET_CAMPAIGN_DATA,
      payload: {
        campaignData
      }
    });
  };
}

export function clearCampaignData() {
  return (dispatch) => {
    dispatch({ type: CLEAR_CAMPAIGN_DATA });
  };
}

export function addCampaign(history, campaign) {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_CAMPAIGN });
      const campaignID = await axios.post('/addCampaign', campaign);
      dispatch(getCampaigns(history));
      return campaignID.data.campaignID;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function deleteCampaign(history, campaignID) {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_CAMPAIGN,
        payload: {
          campaignID
        }
      });
      await axios.post('/deleteCampaign', { campaignID });
      history.push('/app/brand/campaigns');
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}
