import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  avatar: {
    height: '100%',
    width: '100%'
  },
  popover: {
    padding: 0,
    width: 200,
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)'
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: 'white',
    '& li': {
      fontWeight: 400,
      paddingTop: 5,
      paddingBottom: 10,
      fontSize: '1rem'
    },
    '& a': {
      fontWeight: 400,
      paddingTop: 10,
      paddingBottom: 5,
      fontSize: '1rem'
    }
  },
  avatarButton: {
    height: 38,
    width: 38
  }
}));

function Account() {
  const classes = useStyles();

  return (
    <>
      <Tooltip title="Your Account">
        <Avatar
          className={classes.avatarButton}
          src="/static/images/account-logo.png"
        />
      </Tooltip>
    </>
  );
}

export default Account;
