/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import ProgrammaticLayout from 'src/layouts/ProgrammaticLayout';
import DocsLayout from 'src/layouts/DocsLayout';
// import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GameAuthGuard from 'src/components/GameAuthGuard';
import GameStudioAuthGuard from 'src/components/GameStudioAuthGuard';
import BrandAuthGuard from 'src/components/BrandAuthGuard';
import CreatorAuthGuard from 'src/components/CreatorAuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import AdminAuthGuard from 'src/components/AdminAuthGuard';
import VerifiedAuthGuard from 'src/components/VerifiedAuthGuard';
import RestrictedDashboardLayout from 'src/layouts/RestrictedDashboardLayout';
import StudioProgrammaticLayout from './layouts/StudioProgrammaticLayout';
import IQLayout from './layouts/IQLayout';
import HomeView from './views/pages/HomeView';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgotPassword',
    component: lazy(() => import('src/views/auth/ForgotPwdView'))
  },
  {
    exact: true,
    guard: VerifiedAuthGuard,
    path: '/gamesignup',
    layout: RestrictedDashboardLayout,
    component: lazy(() => import('src/views/auth/OldRegisterView/GameSignUp'))
  },
  {
    exact: true,
    guard: VerifiedAuthGuard,
    path: '/brandsignup',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/auth/OldRegisterView/BrandSignUp'))
  },
  {
    exact: true,
    guard: VerifiedAuthGuard,
    path: '/studiosignup',
    layout: RestrictedDashboardLayout,
    component: lazy(() => import('src/views/auth/OldRegisterView/StudioSignup'))
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app/explore/browse',
    component: lazy(() => import('src/views/posts/AllPostsBrowseView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app/explore',
    component: () => <Redirect to="/app/explore/browse" />
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app/explore/post/:id',
    component: lazy(() => import('src/views/posts/PostDetailsView'))
  },
  {
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app/explore/user/:name',
    component: lazy(() => import('src/views/admin_side/pages/ProfileView'))
  },
  {
    path: '/app/brand',
    guard: BrandAuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/brand',
        component: () => <Redirect to="/app/brand/branddashboard" />
      },
      {
        exact: true,
        path: '/app/brand/itemdashboard/:name',
        component: lazy(() =>
          import('src/views/brand_side/pages/ItemDashboardDetailed')
        )
      },
      {
        exact: true,
        path: '/app/brand/gamedashboard/:name',
        component: lazy(() =>
          import('src/views/brand_side/pages/GameDashboardDetailed')
        )
      },
      {
        exact: true,
        path: '/app/brand/account',
        component: lazy(() => import('src/views/brand_side/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/brand/branddashboard',
        component: lazy(() => import('src/views/brand_side/hub/BrandDashboard'))
      },
      {
        exact: true,
        path: '/app/brand/partnered-games',
        component: lazy(() =>
          import('src/views/brand_side/hub/PartneredGamesView')
        )
      },
      {
        exact: true,
        path: '/app/brand/campaigns',
        component: lazy(() =>
          import('src/views/brand_side/pages/Campaigns/CampaignBrowseView')
        )
      },
      {
        exact: true,
        path: '/app/brand/create-campaign',
        component: lazy(() =>
          import('src/views/brand_side/pages/Campaigns/CampaignCreateView')
        )
      },
      {
        exact: true,
        path: '/app/brand/campaign/:id',
        component: lazy(() =>
          import('src/views/brand_side/pages/Campaigns/CampaignDetailsView')
        )
      },
      {
        exact: true,
        path: '/app/brand/create-proposal',
        component: lazy(() =>
          import('src/views/brand_side/pages/Creator/CreateProposal')
        )
      },
      {
        exact: true,
        path: '/app/brand/maps',
        component: lazy(() =>
          import('src/views/brand_side/pages/Creator/AllMapsBrowseView')
        )
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/app/studio',
    guard: GameStudioAuthGuard,
    layout: StudioProgrammaticLayout,
    routes: [
      {
        exact: true,
        path: '/app/studio',
        component: () => <Redirect to="/app/studio/StudioDashboard" />
      },
      {
        exact: true,
        path: '/app/studio/StudioDashboard',
        component: lazy(() => import('src/views/studio_programmatic/Overview'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/app/admin',
    guard: AdminAuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/admin',
        component: () => <Redirect to="/app/admin/AdminDashboard" />
      },
      {
        exact: true,
        path: '/app/admin/itemdashboard/:name',
        component: lazy(() =>
          import('src/views/admin_side/pages/ItemDashboardDetailed')
        )
      },
      {
        exact: true,
        path: '/app/admin/gamedashboard/:name',
        component: lazy(() =>
          import('src/views/admin_side/pages/GameDashboardDetailed')
        )
      },
      {
        exact: true,
        path: '/app/admin/branddashboard/:name',
        component: lazy(() =>
          import('src/views/admin_side/pages/BrandDashboardDetailed')
        )
      },
      {
        exact: true,
        path: '/app/admin/account',
        component: lazy(() => import('src/views/admin_side/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/admin/account/:name',
        component: lazy(() => import('src/views/admin_side/hub/AccountView'))
      },
      {
        exact: true,
        path: '/app/admin/AdminDashboard',
        component: lazy(() => import('src/views/admin_side/hub/AdminDashboard'))
      },
      {
        exact: true,
        path: '/app/admin/games',
        component: lazy(() => import('src/views/admin_side/hub/GamesView'))
      },
      {
        exact: true,
        path: '/app/admin/brands',
        component: lazy(() => import('src/views/admin_side/hub/BrandsView'))
      },
      {
        exact: true,
        path: '/app/admin/studios',
        component: lazy(() => import('src/views/admin_side/hub/StudiosView'))
      },
      {
        exact: true,
        path: '/app/admin/items',
        component: lazy(() => import('src/views/admin_side/hub/ItemsView'))
      },
      {
        exact: true,
        path: '/app/admin/actions',
        component: lazy(() =>
          import('src/views/admin_side/hub/AdminActionsView')
        )
      },
      {
        exact: true,
        path: '/app/admin/assets',
        component: lazy(() =>
          import('src/views/admin_side/hub/AssetManagerView')
        )
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/app/creator',
    guard: CreatorAuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/creator',
        component: () => <Redirect to="/app/creator/editProfile" />
      },
      {
        exact: true,
        path: '/app/creator/editProfile',
        component: lazy(() => import('src/components/EditProfilePage'))
      }
    ]
  },
  {
    path: '/iq-demo/games',
    routes: [
      {
        exact: true,
        path: '/iq-demo/games',
        component: lazy(() => import('src/views/iq/Games'))
      }
    ]
  },
  {
    path: '/iq-demo/dashboard',
    layout: IQLayout,
    routes: [
      {
        exact: true,
        path: '/iq-demo/dashboard',
        component: () => <Redirect to="/iq-demo/dashboard/overview" />
      },
      {
        exact: true,
        path: '/iq-demo/dashboard/overview',
        component: lazy(() => import('src/views/iq/Overview'))
      },
      {
        exact: true,
        path: '/iq-demo/dashboard/rewards',
        component: lazy(() => import('src/views/iq/Rewards'))
      },
      {
        exact: true,
        path: '/iq-demo/dashboard/objects',
        component: lazy(() => import('src/views/iq/Objects'))
      },
      {
        exact: true,
        path: '/iq-demo/dashboard/quests',
        component: lazy(() => import('src/views/iq/Quests'))
      }
    ]
  },
  {
    path: '/app',
    guard: GameAuthGuard,
    layout: ProgrammaticLayout,
    // layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/game/overview" />
      },
      // {
      //   exact: true,
      //   path: '/app/game/account',
      //   component: lazy(() => import('src/views/game_side/pages/AccountView'))
      // },
      {
        exact: true,
        path: '/app/game/placements',
        component: lazy(() => import('src/views/programmatic/Placements'))
        // path: '/app/hub/itemdashboard',
        // component: lazy(() => import('src/components/ItemDashboard'))
      },
      {
        exact: true,
        path: '/app/game/compliance',
        component: lazy(() => import('src/views/programmatic/Compliance'))
      },
      {
        exact: true,
        path: '/app/game/settings',
        component: lazy(() => import('src/views/programmatic/Settings'))
      },
      {
        exact: true,
        path: '/app/game/overview',
        component: lazy(() => import('src/views/programmatic/Overview'))
        // exact: true,
        // path: '/app/hub/gamedashboard',
        // component: lazy(() => import('src/views/game_side/hub/GameDashboard'))
      },
      {
        exact: true,
        path: '/app/game/assets',
        component: lazy(() =>
          import('src/views/game_side/hub/AssetManagerView')
        )
      },
      {
        exact: true,
        path: '/app/game',
        component: () => <Redirect to="/app/game/Placements" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/quickstart',
        component: lazy(() => import('src/views/docs/QuickStartView'))
      },
      {
        exact: true,
        path: '/docs/commands',
        component: lazy(() => import('src/views/docs/CommandsView'))
      },
      {
        exact: true,
        path: '/docs/commands/track',
        component: lazy(() => import('src/views/docs/TrackView'))
      },
      {
        exact: true,
        path: '/docs/commands/untrack',
        component: lazy(() => import('src/views/docs/UntrackView'))
      },
      {
        exact: true,
        path: '/docs/programmaticDoc',
        component: lazy(() => import('src/views/docs/UnitySDKView'))
      },
      {
        exact: true,
        path: '/docs/restapi',
        component: lazy(() => import('src/views/docs/RESTAPIView'))
      },
      {
        exact: true,
        path: '/docs/clickthrough',
        component: lazy(() => import('src/views/docs/ClickthroughView'))
      },
      {
        exact: true,
        path: '/docs/faqs',
        component: lazy(() => import('src/views/docs/FAQView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/ApiCallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/about',
    routes: [
      {
        exact: true,
        path: '/about',
        component: lazy(() => import('src/views/pages/AboutView'))
      }
    ]
  },
  {
    path: '/programmatic',
    routes: [
      {
        exact: true,
        path: '/programmatic',
        component: lazy(() => import('src/views/pages/ProgrammaticView'))
      }
    ]
  },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard key={route.gameDataAccess}>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
