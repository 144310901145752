import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';

const useStyles = makeStyles(() => ({
  ...Typography,
  root: {
    flex: 1,
    fontFamily: 'Elza',
    padding: '2rem',
    // padding: '1.5rem 2rem',
    background: COLORS.WHITE.default,
    borderRadius: '0.5rem'
  },
  iconContainer: {
    background: COLORS.PRIMARY.default,
    borderRadius: '0.25rem',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    ...Typography.h6,
    fontWeight: '600',
    marginBottom: '0.5rem'
  },
  bodyContainer: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: '1.5rem'
  },
  description: {
    ...Typography.p,
    width: '100%'
  }
}));

function MobileInfoCard(props) {
  const classes = useStyles();
  const { title, description, icon, animated = false } = props;

  return (
    <div data-aos={animated ? 'zoom-out' : 'none'} className={classes.root}>
      <h6 className={classes.title}>{title}</h6>
      <div className={classes.bodyContainer}>
        {/* <div className={classes.iconContainer}>{icon}</div> */}
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
}

MobileInfoCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
  animated: PropTypes.bool
};

export default MobileInfoCard;
