import moment from 'moment';
import { momentf, today } from 'src/utils/date';

export const formatGameData = (game, admin = false) => {
  if (game) {
    formatData(game.logins, admin);
    formatData(game.newPlayers, admin);
  }
};

export const formatManyGames = (games) => {
  for (var key in games) {
    formatGameData(games[key]);
  }
};

export const formatItemData = (item, admin = false) => {
  if (item) {
    item['clicks'] = item.clicks ? item.clicks : [];
    formatData(item.clicks, admin, true);
    formatData(item.looks, admin);
    formatData(item.obtains, admin);
    formatData(item.purchases, admin);
    formatData(item.uses, admin);
  }
};

/**
 * Function that every chart data is passed through
 *
 * Takes series data, sorts it, then splits into dates and values arrays
 * Additionally, generates demo data if it finds a `demoInfo` map
 * @param data
 */
export const formatData = (data, admin = false, clicks = false) => {
  if (data) {
    if (data.hasOwnProperty('demoInfo') && !admin) {
      // Demo purposes
      let generatedData = generateData(data.demoInfo);
      data['dates'] = generatedData.dates;
      if (clicks) {
        data['values'] = generatedData.values.map((x) => Math.round(x / 10));
        data['total'] = Math.round(generatedData.total / 10);
      } else {
        data['values'] = generatedData.values;
        data['total'] = generatedData.total;
      }
    } else if (data.series) {
      let series = data.series;
      let keys = Object.keys(data.series);
      if (!data.dates || !data.values) {
        if (keys.length === 0 || !data.series) {
          // If series data is empty, add default values
          data['dates'] = [today()];
          data['values'] = [0];
        } else {
          // If there is series data, just make empty arrays
          data['dates'] = [];
          data['values'] = [];
        }
      }
      //sort the series keys by date
      const sortedKeys = keys.sort(
        (a, b) =>
          new momentf(a).format('YYYYMMDD') - new momentf(b).format('YYYYMMDD')
      );
      // Fill dates and values array with sorted date labels and corresponding values
      for (let i = 0; i < sortedKeys.length; i++) {
        const date = (element) => element === sortedKeys[i];
        let index = data.dates.findIndex(date);
        if (index === -1) {
          data.dates.push(sortedKeys[i]);
          data.values.push(series[sortedKeys[i]]);
        } else {
          data.values[index] += series[sortedKeys[i]];
        }
      }
    } else {
      // If data is just an empty array with no series, dates, or values arrays
      data['dates'] = [today()];
      data['values'] = [0];
    }
  }
};

/**
 * Generates demo data given the following parameters.
 * More information here:
 * https://www.notion.so/relentlo/Generating-Demo-Data-How-to-57eb2ceee8b44285a3c4260cdd034f73
 */
const generateData = ({
  slope = 70,
  startDate = '2021-01-01 UTC',
  endDate = today(),
  randomness = 2000,
  curve = 0.4,
  freq = 30
}) => {
  let data = {
    dates: [],
    values: [],
    total: 0
  };
  let dates = enumerateDaysBetweenDates(startDate, endDate);
  let x = 0;
  let freq_num = 0;
  dates.forEach((date) => {
    let rand = Math.floor(Math.random() * randomness);
    let num =
      line(slope, x) + rand + Math.floor(1000 * Math.sin(curve * freq_num));
    if (num < 0) num = rand;
    // Add generated data to our dict
    // data.series[date] = num;
    data.dates.push(date);
    data.values.push(num);
    data.total += num;
    // Change needed variables
    x += 1;
    freq_num = freq_num >= freq ? 0 : freq_num + 1;
  });
  return data;
};

/**
 * Simple line
 */
const line = (m, x) => {
  return m * x;
};

/**
 * Makes a list of dates between the given start and end dates
 * @param startDate
 * @param endDate
 * @returns
 */
const enumerateDaysBetweenDates = (startDate, endDate) => {
  let date = [];
  startDate = momentf(startDate);
  endDate = momentf(endDate).add(1, 'days');

  for (var m = moment(startDate); m.isBefore(endDate); m.add(1, 'days')) {
    date.push(m.format('YYYY-MM-DD UTC'));
  }
  return date;
};
