import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';
import { DISPLAY_VARIANTS } from 'src/webkit/constants/DisplayVariants';

const getBackground = (style) => {
  switch (style) {
    case DISPLAY_VARIANTS.STYLES.PRIMARY:
      return COLORS.WHITE.default;
    case DISPLAY_VARIANTS.STYLES.DARK:
      return COLORS.BLACK.dark;
    default:
      return COLORS.WHITE.default;
  }
};

const getPrimaryColor = (style) => {
  switch (style) {
    case DISPLAY_VARIANTS.STYLES.PRIMARY:
      return COLORS.PRIMARY.default;
    case DISPLAY_VARIANTS.STYLES.DARK:
      return COLORS.WHITE.default;
    default:
      return COLORS.PRIMARY.default;
  }
};

const getSecondaryColor = (style) => {
  switch (style) {
    case DISPLAY_VARIANTS.STYLES.PRIMARY:
      return COLORS.BLACK.default;
    case DISPLAY_VARIANTS.STYLES.DARK:
      return COLORS.WHITE.default;
    default:
      return COLORS.BLACK.default;
  }
};

const useStyles = (props) =>
  makeStyles(() => ({
    ...Typography,
    root: {
      fontFamily: 'Elza',
      width: '100%',
      padding: '3rem 5rem',
      background: props.bgColor,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '4rem',
      borderRadius: '0.5rem',
      color: props.secondaryTextColor
    },
    titleContainer: {
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start'
    },
    statsContainer: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      gap: '4rem'
    },
    valueContainer: {
      '& h2': {
        ...Typography.h2,
        fontWeight: '500',
        color: props.primaryTextColor
      }
    }
  }));

function DefaultStatsDisplay(props) {
  const {
    title,
    data,
    animated = false,
    style = DISPLAY_VARIANTS.PRIMARY
  } = props;

  const bgColor = getBackground(style);
  const primaryTextColor = getPrimaryColor(style);
  const secondaryTextColor = getSecondaryColor(style);

  const classes = useStyles({
    bgColor,
    primaryTextColor,
    secondaryTextColor
  })();

  return (
    <div data-aos={animated ? 'zoom-out' : 'none'} className={classes.root}>
      <div className={classes.titleContainer}>
        <h4 className={classes.h4}>{title}</h4>
      </div>
      <div className={classes.statsContainer}>
        {data.map((pair) => (
          <div key={pair.title} className={classes.valueContainer}>
            <h2 className={classes.h2}>{pair.value}</h2>
            <p className={classes.p}>{pair.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

DefaultStatsDisplay.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  animated: PropTypes.bool,
  style: PropTypes.number
};

export default DefaultStatsDisplay;
