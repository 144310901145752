import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function AuthGuard({ children }) {
  const account = useSelector(state => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }
  if (!account.user.verified) {
    if (account.user.type === "game") {
      return <Redirect to="/gamesignup" />;
    }
    if (account.user.type === "studio") {
      return <Redirect to="/studiosignup" />;
    }
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
