import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';

const useStyles = makeStyles(() => ({
  ...Typography,
  root: {
    fontFamily: 'Elza',
    width: '100%',
    padding: '0'
  },
  titleContainer: {
    marginBottom: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  values: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '0.5rem'
  },
  valueContainer: {
    width: '100%',
    padding: '2rem',
    // padding: '1.5rem 2rem',
    borderRadius: '0.5rem',
    background: COLORS.WHITE.default,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '1rem',
    '& h4': {
      ...Typography.h4,
      fontWeight: '500',
      color: COLORS.PRIMARY.default
    }
  }
}));

function MobileStatsDisplay(props) {
  const classes = useStyles();
  const { title, data, animated = false } = props;

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <h5 className={classes.h5}>{title}</h5>
      </div>
      <div className={classes.values}>
        {data.map((pair) => (
          <div
            key={pair.title}
            data-aos={animated ? 'zoom-out' : 'none'}
            data-aos-offset="-100"
            data-aos-mirror="true"
            className={classes.valueContainer}
          >
            <h4 className={classes.h4}>{pair.value}</h4>
            <p className={classes.p}>{pair.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

MobileStatsDisplay.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  animated: PropTypes.bool
};

export default MobileStatsDisplay;
