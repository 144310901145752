import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';
import { BUTTON_VARIANTS } from 'src/webkit/constants/ButtonVariants';
import OutlinedPillButton from 'src/webkit/components/buttons/OutlinedPillButton';
import { DISPLAY_VARIANTS } from 'src/webkit/constants/DisplayVariants';

const getBackground = (style) => {
  switch (style) {
    case DISPLAY_VARIANTS.STYLES.PRIMARY:
      return COLORS.WHITE.default;
    case DISPLAY_VARIANTS.STYLES.DARK:
      return COLORS.BLACK.dark;
    default:
      return COLORS.WHITE.default;
  }
};

const getColor = (style) => {
  switch (style) {
    case DISPLAY_VARIANTS.STYLES.PRIMARY:
      return COLORS.PRIMARY.default;
    case DISPLAY_VARIANTS.STYLES.DARK:
      return COLORS.WHITE.default;
    default:
      return COLORS.PRIMARY.default;
  }
};

const getButtonColor = (style) => {
  switch (style) {
    case DISPLAY_VARIANTS.STYLES.PRIMARY:
      return BUTTON_VARIANTS.STYLES.PRIMARY;
    case DISPLAY_VARIANTS.STYLES.DARK:
      return BUTTON_VARIANTS.STYLES.INVERTED;
    default:
      return BUTTON_VARIANTS.STYLES.PRIMARY;
  }
};

const useStyles = (props) =>
  makeStyles(() => ({
    ...Typography,
    root: {
      fontFamily: 'Elza',
      width: '100%',
      paddingLeft: '5rem',
      display: 'flex',
      borderRadius: '0.5rem',
      alignItems: 'stretch',
      position: 'relative',
      background: props.bgColor,
      color: props.textColor
    },
    textContainer: {
      marginTop: '7.5rem',
      marginBottom: '5rem',
      maxWidth: '70%',
      zIndex: '10',
      '& p': {
        marginTop: '0.75rem',
        marginBottom: '1rem'
      }
    },
    imgContainer: {
      position: 'absolute',
      height: '100%',
      right: '5rem',
      bottom: '0',
      zIndex: '0'
    },
    image: {
      maxHeight: '100%'
    }
  }));

function DefaultCTA(props) {
  const {
    title,
    description,
    buttonTitle,
    buttonAction,
    img,
    style = DISPLAY_VARIANTS.STYLES.PRIMARY
  } = props;

  const bgColor = getBackground(style);
  const textColor = getColor(style);
  const buttonVariant = getButtonColor(style);

  const classes = useStyles({
    bgColor,
    textColor
  })();

  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        <h3 className={classes.h3}>{title}</h3>
        <p className={classes.p}>{description}</p>
        <OutlinedPillButton
          title={buttonTitle}
          onClick={buttonAction}
          style={buttonVariant}
        />
      </div>
      <div className={classes.imgContainer}>
        <img className={classes.image} src={img} alt="Character" />
      </div>
    </div>
  );
}

DefaultCTA.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonAction: PropTypes.any,
  img: PropTypes.string,
  style: PropTypes.number
};

export default DefaultCTA;
