import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';
import { DISPLAY_VARIANTS } from 'src/webkit/constants/DisplayVariants';

const getBackground = (style) => {
  switch (style) {
    case DISPLAY_VARIANTS.STYLES.PRIMARY:
      return COLORS.WHITE.default;
    case DISPLAY_VARIANTS.STYLES.DARK:
      return COLORS.BLACK.dark;
    default:
      return COLORS.WHITE.default;
  }
};

const getSecondaryBackground = (style) => {
  switch (style) {
    case DISPLAY_VARIANTS.STYLES.PRIMARY:
      return COLORS.PRIMARY.default;
    case DISPLAY_VARIANTS.STYLES.DARK:
      return COLORS.BLACK[100];
    default:
      return COLORS.PRIMARY.default;
  }
};

const getColor = (style) => {
  switch (style) {
    case DISPLAY_VARIANTS.STYLES.PRIMARY:
      return COLORS.BLACK.default;
    case DISPLAY_VARIANTS.STYLES.DARK:
      return COLORS.WHITE.default;
    default:
      return COLORS.BLACK.default;
  }
};

const useStyles = (props) =>
  makeStyles(() => ({
    ...Typography,
    root: {
      fontFamily: 'Elza',
      width: '100%',
      borderRadius: '0.5rem',
      padding: '3rem 5rem',
      background: props.bgColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: props.centerContent ? 'center' : 'start',
      gap: '3rem',
      color: props.textColor
    },
    iconContainer: {
      background: props.secondaryBgColor,
      padding: '1.5rem',
      borderRadius: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    textContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: props.centerContent ? 'center' : 'start',
      gap: '0.5rem'
    },
    title: {
      ...Typography.h5,
      fontWeight: '600'
    }
  }));

function DefaultInfoCard(props) {
  const {
    title,
    description,
    icon,
    animated = false,
    centerContent = false,
    style = DISPLAY_VARIANTS.PRIMARY
  } = props;

  const bgColor = getBackground(style);
  const secondaryBgColor = getSecondaryBackground(style);
  const textColor = getColor(style);

  const classes = useStyles({
    bgColor,
    secondaryBgColor,
    textColor,
    centerContent
  })();

  return (
    <div data-aos={animated ? 'zoom-out' : 'none'} className={classes.root}>
      <div className={classes.iconContainer}>{icon}</div>
      <div className={classes.textContainer}>
        <h5 className={classes.title}>{title}</h5>
        <p className={classes.p}>{description}</p>
      </div>
    </div>
  );
}

DefaultInfoCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
  animated: PropTypes.bool,
  style: PropTypes.number,
  centerContent: PropTypes.bool
};

export default DefaultInfoCard;
