import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function CreatorAuthGuard({ children }) {
  const account = useSelector((state) => state.account);

  if (!account.user) {
    return <Redirect to="/login" />;
  }
  if (account.user.email === 'admin@relentlo.com') {
    return <Redirect to="/app/admin" />;
  }
  if (!account.user.verified) {
    return <Redirect to="/gamesignup" />;
  }
  if (account.user.type === 'game') {
    return <Redirect to="/app" />;
  }
  if (account.user.type === 'brand') {
    return <Redirect to="/app/brand" />;
  }
  if (account.user.type === 'studio') {
    return <Redirect to="/app/studio" />;
  }
  return children;
}

CreatorAuthGuard.propTypes = {
  children: PropTypes.any
};

export default CreatorAuthGuard;
