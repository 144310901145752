import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, Fab, Tooltip } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { LogOut } from 'react-feather';
import authService from 'src/services/authService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  logOutGame: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(6),
    zIndex: 1
  }
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const history = useHistory();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [logoutGameButton, setLogoutGameButton] = useState(false);
  const [logoutUserButton, setLogoutUserButton] = useState(false);
  const user = useSelector((state) => state.account.user);
  const studioGame = authService.getStudioGame();
  const userName = authService.getUser();

  useEffect(() => {
    setLogoutGameButton(studioGame ? true : false);
    setLogoutUserButton(userName && !studioGame ? true : false);
  }, [studioGame, userName]);

  const logoutGame = () => {
    history.push('/app/studio/games');
  };

  const logoutUser = (type) => {
    history.push(`/app/admin/${type}s`);
  };

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      {logoutGameButton && (
        <Tooltip title="Return to Studio" placement="top">
          <Fab
            className={classes.logOutGame}
            onClick={logoutGame}
            color="primary"
            aria-label="add"
          >
            <LogOut />
          </Fab>
        </Tooltip>
      )}
      {logoutUserButton && (
        <Tooltip title="Return to Admin" placement="top">
          <Fab
            className={classes.logOutGame}
            onClick={() => logoutUser(user.type)}
            color="primary"
            aria-label="add"
          >
            <LogOut />
          </Fab>
        </Tooltip>
      )}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;