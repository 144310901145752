import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/webkit/foundation/Colors';

const useStyles = makeStyles(() => ({
  cardContainer: {
    height: '70px',
    width: '175px',
    minWidth: '175px',
    background: COLORS.WHITE.default,
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingInline: '0',
    position: 'relative',
    marginInlineEnd: '0.5rem',
    '& img': {
      height: '100%',
      width: 'auto',
      margin: 'auto'
    }
  }
}));

const Card = (props) => {
  const { src } = props;
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
      <img alt="partner logo" src={src} />
    </div>
  );
};

Card.propTypes = {
  src: PropTypes.string
};

export default Card;
