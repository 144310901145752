// eslint-disable-next-line import/prefer-default-export
export const COLORS = {
  PRIMARY: {
    default: '#4019DC',
    100: '#5D3CE2',
    80: '#7A5FE7',
    60: '#9883EC',
    40: '#B6A7F1',
    20: '#D3CAF7',
    10: '#E2DCFA',
    5: '#F0EDFC'
  },
  RED: {
    default: '#FF253B',
    100: '#F67B7B',
    80: '#F78D8D',
    60: '#F8A0A0',
    40: '#F9B3B3',
    20: '#FAC6C6',
    10: '#FCD9D9',
    5: '#FDECEC'
  },
  BLUE: {
    default: '#006AF5',
    100: '#1F80FF',
    80: '#4797FF',
    60: '#70AEFF',
    40: '#99C5FF',
    20: '#C2DCFF',
    10: '#D6E8FF',
    5: '#EBF3FF'
  },
  GREEN: {
    120: '#3A927D',
    default: '#45AF96',
    80: '#5FBFA9',
    60: '#7CCBB8',
    40: '#99D6C8',
    20: '#B6E2D8',
    10: '#D3EEE7',
    5: '#F0F9F7'
  },
  YELLOW: {
    120: '#E08700',
    default: '#FF9D0A',
    80: '#FFAD33',
    60: '#FFBE5C',
    40: '#FFCE85',
    20: '#FFDEAD',
    10: '#FFEFD6',
    5: '#FFF7EB'
  },
  BLACK: {
    default: '#000',
    100: '#25282B',
    80: '#52575C',
    60: '#A0A4A8',
    40: '#CACCCF',
    20: '#DBDDE0',
    10: '#E8E8E8',
    5: '#F9F9FA',
    dark: '#2D3134'
  },
  WHITE: {
    default: '#fff'
  },
  TRANSPARENT: {
    default: 'transparent'
  }
};
