import axios from 'src/utils/axios';
import authService from 'src/services/authService';
import {
  getItem,
  getItems,
  ADD_ITEM,
  CLEAR_ITEMS
} from 'src/actions/itemActions';
import {
  getPosts,
  getAllPosts,
  // getRecommendedPosts,
  CLEAR_POSTS
} from 'src/actions/postActions';
import { getCampaigns, CLEAR_CAMPAIGNS } from 'src/actions/campaignActions';
import { getAdminData, getAllItems } from 'src/actions/adminActions';
import { formatGameData, formatManyGames } from 'src/utils/formatData';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const SESSION_EXPIRED = '@account/session-expired';
export const SET_TEMP_GAME_IMAGES = '@account/set-temp-game-images';
export const DELETE_GAME_IMAGE = '@account/delete-game-image';
export const SET_PARTNERED_GAMES = '@account/set-partnered-games';
export const GAME_DATA_SUCCESS = '@account/game-data-success';
export const MAP_DATA_SUCCESS = '@account/map-data-success';
export const ADD_GAME = '@account/add-game';
export const SET_USER_STUDIO = '@account/set-user-studio';
export const SET_ADMIN = '@account/set-admin';
export const FINISHED_LOADING = '@account/finished-loading';

export function login(email = null, password = null) {
  return async (dispatch) => {
    try {
      if (email) {
        dispatch({ type: LOGIN_REQUEST });
        await authService.loginWithEmailAndPassword(email, password);
      }

      const user = await authService.loginInWithToken();

      await dispatch(getAllPosts());
      await dispatch(getMaps());
      if (user.type === 'game') {
        await dispatch(getPosts());
      }
      if (user.type === 'brand' || user.type === 'studio') {
        await dispatch(getUsers());
      }
      if (user.type === 'brand') {
        await dispatch(getCampaigns());
        // await dispatch(getRecommendedPosts());
      }
      if (user.name === 'Relentlo Admin') {
        await dispatch(getAllItems());
        await dispatch(getAdminData());
      } else {
        await dispatch(getItems());
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

/* Returns a specific user by (game) name. */
export function getUser(name, history) {
  return async (dispatch) => {
    try {
      let userData = await axios.get(`/${name}/getUserData`);
      formatGameData(userData.data);
      return userData.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        if (history) {
          history.push('/login');
        }
      }
    }
  };
}

export function getUsers(history) {
  return async (dispatch) => {
    try {
      let usersData = await axios.get(`/getGames`);
      let data = usersData.data;
      formatManyGames(data);
      dispatch({ type: GAME_DATA_SUCCESS, payload: { data } });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        if (history) {
          history.push('/login');
        }
      }
    }
  };
}

export function getMaps(history) {
  return async (dispatch) => {
    try {
      let mapData = await axios.get(`/getMaps`);
      let data = mapData.data;
      dispatch({ type: MAP_DATA_SUCCESS, payload: { data } });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        if (history) {
          history.push('/login');
        }
      }
    }
  };
}

export function addGame(history, game) {
  return async (dispatch) => {
    try {
      await axios.post('/addGame', { game });
      const gameData = await dispatch(getUser(game, history));

      dispatch({
        type: ADD_GAME,
        payload: {
          gameData
        }
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function forgotPwd(email) {
  return async (dispatch) => {
    try {
      await authService.resetPassword(email);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user
      }
    });
}

export function logout() {
  return (dispatch) => {
    authService.logout();

    dispatch({ type: LOGOUT });
    dispatch({ type: CLEAR_ITEMS });
    dispatch({ type: CLEAR_POSTS });
    dispatch({ type: CLEAR_CAMPAIGNS });
  };
}

/**
 * Things to change to enable register:
 * 1. Uncomment register below, delete other register function
 * 2. Set session token in authService
 */
// export function register(newUserData) {
//   return async dispatch => {
//     try {
//       dispatch({ type: LOGIN_REQUEST });
//       await authService.register(newUserData);
//       const user = await authService.loginInWithToken();

//       dispatch({
//         type: LOGIN_SUCCESS,
//         payload: {
//           user
//         }
//       });
//     } catch (error) {
//       dispatch({ type: LOGIN_FAILURE });
//       throw error;
//     }
//   };
// }

export function register(newUserData) {
  return async (dispatch) => {
    try {
      await authService.register(newUserData);
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function updateProfile(history, update) {
  return async (dispatch) => {
    try {
      await axios.post('/editProfile', update);
      let user = update.game
        ? await dispatch(getUser(update.game, null))
        : await authService.loginInWithToken();

      dispatch({
        type: UPDATE_PROFILE,
        payload: { user: user, game: update.game }
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function updateCompliance(history, update) {
  return async (dispatch) => {
    try {
      await axios.post('/editProgrammaticCompliance', update);
      const user = await authService.loginInWithToken();

      dispatch({
        type: UPDATE_PROFILE,
        payload: { user }
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function updateDemographics(history, update) {
  return async (dispatch) => {
    try {
      await axios.post('/editDemographics', update);
      const user = await authService.loginInWithToken();

      dispatch({
        type: UPDATE_PROFILE,
        payload: { user }
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function uploadProfilePicture(history, formData) {
  return async (dispatch) => {
    try {
      await axios.post('/uploadUserImage', formData);
      const user = await authService.loginInWithToken();

      dispatch({
        type: UPDATE_PROFILE,
        payload: { user }
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function uploadGameImages(history, images) {
  return (dispatch) => {
    try {
      let formData = new FormData();
      let tempGameImageURLs = [];
      images.forEach((image) => {
        formData.append('images', image);
        tempGameImageURLs.push(URL.createObjectURL(image));
      });

      axios.post('/uploadGameImages', formData);

      dispatch({
        type: SET_TEMP_GAME_IMAGES,
        payload: { tempGameImages: images, tempGameImageURLs }
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function uploadGameAsset(history, asset, itemName) {
  return (dispatch) => {
    try {
      let formData = new FormData();
      formData.append('asset', asset);
      formData.append('item', itemName);

      axios.post('/uploadGameAsset', formData);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function uploadBundleAsset(history, asset, gameName, itemName, tabName) {
  return (dispatch) => {
    try {
      let formData = new FormData();
      formData.append('asset', asset);
      formData.append('item', itemName);
      formData.append('game', gameName);
      formData.append('folder', tabName);

      axios.post('/uploadGameAsset', formData);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function deleteGameImage(history, index) {
  return (dispatch) => {
    try {
      axios.post('/deleteGameImage', { index });

      dispatch({
        type: DELETE_GAME_IMAGE,
        payload: { index }
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function purchasePost(history, partneredGameData, item) {
  return async (dispatch) => {
    try {
      await axios.post('/addPartneredGame', partneredGameData);
      const partneredGames = await axios.get('/getGames');
      const itemData = await dispatch(getItem(item));
      dispatch(getAllPosts(history));
      // dispatch(getRecommendedPosts(history));
      dispatch({
        type: SET_PARTNERED_GAMES,
        payload: { partneredGames: partneredGames.data }
      });
      dispatch({
        type: ADD_ITEM,
        payload: {
          itemName: item,
          itemData,
          defaultItem: item
        }
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function uploadAssetBundle(history, formData) {
  return async (dispatch) => {
    try {
      await axios.post('/uploadAssetBundle', formData);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function removeGameID(history) {
  return async (dispatch) => {
    try {
      authService.setStudioGame(null);
      dispatch({ type: SET_USER_STUDIO });
      await dispatch(login());
      dispatch({ type: FINISHED_LOADING });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}

export function removeUserID(history) {
  return async (dispatch) => {
    try {
      authService.setUser(null);
      dispatch({ type: SET_ADMIN });
      await dispatch(login());
      dispatch({ type: FINISHED_LOADING });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        dispatch({ type: SESSION_EXPIRED });
        history.push('/login');
      }
      throw error;
    }
  };
}
