import { makeStyles } from '@material-ui/core';
import React from 'react';
import { COLORS } from 'src/webkit/foundation/Colors';
import 'aos/dist/aos.css';
import MobileStatsDisplay from 'src/webkit/layouts/statsDisplay/MobileStatsDisplay';
import MobileInfoCard from 'src/webkit/layouts/infoCard/MobileInfoCard';
import ExtensionIcon from '@mui/icons-material/Extension';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Elza',
    width: '100%',
    background: COLORS.BLACK[5],
    paddingLeft: '2rem',
    paddingRight: '2rem',
    // paddingLeft: '1rem',
    // paddingRight: '1rem',
    paddingTop: '6rem',
    paddingBottom: '3rem',
    overflowX: 'hidden'
  },
  statsWrapper: {
    marginTop: '1.5rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem'
  }
}));

const MobileStats = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MobileStatsDisplay
        animated
        title="Innovative Experiences that Scale"
        data={[
          { value: '1.2B+', title: 'Downloads' },
          { value: '30+', title: 'Unique Games' },
          { value: '200M+', title: 'Monthly Users' }
        ]}
      />
      <div className={classes.statsWrapper}>
        <MobileInfoCard
          animated
          title="Use Our Plugin"
          description="Our lightweight and easy-to-implement plugin adds branded experiences to your game without disrupting players and increasing engagement."
          icon={
            <ExtensionIcon
              style={{ fill: COLORS.WHITE.default, fontSize: '24px' }}
            />
          }
          justifyContent="center"
        />
        <MobileInfoCard
          animated
          title="Work With Top Creators"
          description="Fortnite, Minecraft, and Roblox content creators can now monetize their work. Spend more time doing what you love, and get paid for it."
          icon={
            <LightbulbIcon
              style={{ fill: COLORS.WHITE.default, fontSize: '24px' }}
            />
          }
          justifyContent="center"
        />
      </div>
    </div>
  );
};

export default MobileStats;
