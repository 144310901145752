import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Menu, MenuItem } from '@mui/material';
import authService from 'src/services/authService';
import Account from './Account';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0 3em',
    paddingTop: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em'
  },
  helpFocus: {
    height: 38,
    width: 38,
    marginLeft: '0.5em',
    backgroundColor: '#F5F6F9'
  },
  name: {
    fontSize: '1rem',
    fontWeight: '400'
  },
  paper: {
    borderRadius: 5,
    marginTop: 8,
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)'
  },
  list: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    background: 'white',
    '& li': {
      fontWeight: 400,
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: '1em'
    },
    '& li:hover': {},
    '& li.Mui-selected': {
      color: 'white',
      background: '#5D3CE2'
    },
    '& li.Mui-selected:hover': {
      background: '#220696'
    }
  },
  navigation: {
    display: 'flex',
    gap: '1em',
    alignItems: 'center'
  },
  backArrow: {
    color: '#A0A4A8'
  }
}));

function TopBar() {
  const classes = useStyles();
  const account = useSelector((state) => state.account);
  const actionRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const loading = useSelector((state) => state.account.loading);

  const versions = {
    'Unity SDK v2019+': 'Relentlo'
  };

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right'
    },
    getContentAnchorEl: null
  };

  return (
    <div className={classes.root}>
      <div className={classes.navigation}>
        {!loading && <h3 className={classes.name}>{account.user.name}</h3>}
      </div>
      <div className={classes.buttons}>
        <Tooltip title="Download SDK">
          <IconButton
            target="_blank"
            ref={actionRef}
            onClick={() => {
              setMenuOpen(true);
            }}
            className={classes.helpFocus}
            aria-label="SDK"
          >
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={actionRef.current}
          onClose={() => setMenuOpen(false)}
          open={isMenuOpen}
          {...menuProps}
          PaperProps={{
            className: classes.menu
          }}
          getContentAnchorEl={null}
        >
          {Object.keys(versions)
            .sort((a, b) => {
              return b - a;
            })
            .map((version) => (
              <MenuItem
                key={version}
                onClick={() => {
                  window.location.href = `https://firebasestorage.googleapis.com/v0/b/relentlo-690aa.appspot.com/o/${versions[version]}.unitypackage?alt=media`;
                  setMenuOpen(false);
                }}
              >
                {version}
              </MenuItem>
            ))}
        </Menu>
        <Tooltip title="Docs">
          <IconButton
            target="_blank"
            href="https://relentlo.com/docs"
            className={classes.helpFocus}
            aria-label="Docs"
          >
            <HelpOutline />
          </IconButton>
        </Tooltip>
        <Account />
      </div>
    </div>
  );
}

export default TopBar;
