import React from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import Account from './Account';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0 2em',
    paddingTop: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em'
  },
  helpFocus: {
    height: 38,
    width: 38,
    marginLeft: '0.5em',
    backgroundColor: '#F5F6F9'
  },
  name: {
    fontSize: '1rem',
    fontWeight: '400'
  },
  paper: {
    borderRadius: 5,
    marginTop: 8,
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)'
  },
  list: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    background: 'white',
    '& li': {
      fontWeight: 400,
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: '1em'
    },
    '& li:hover': {},
    '& li.Mui-selected': {
      color: 'white',
      background: '#5D3CE2'
    },
    '& li.Mui-selected:hover': {
      background: '#220696'
    }
  },
  navigation: {
    display: 'flex',
    gap: '1em',
    alignItems: 'center'
  },
  backArrow: {
    color: '#A0A4A8'
  }
}));

function TopBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.navigation}>
        <h3 className={classes.name}>Relentlo IQ Demo</h3>
      </div>
      <div className={classes.buttons}>
        <Tooltip title="Docs">
          <IconButton
            target="_blank"
            href="https://relentlo.com/docs"
            className={classes.helpFocus}
            aria-label="Docs"
          >
            <HelpOutline />
          </IconButton>
        </Tooltip>
        <Account />
      </div>
    </div>
  );
}

export default TopBar;
