import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = (direction = 'right') => {
  let animationStyle = '$scroll 80s linear infinite';
  if (direction === 'right') {
    animationStyle += ' reverse';
  }

  const compiledStyles = makeStyles(() => ({
    '@keyframes scroll': {
      '0%': {
        transform: 'translateX(0)'
      },
      '100%': {
        transform: 'translateX(-50%)'
      }
    },
    carouselWrapper: {
      display: 'flex',
      overflow: 'hidden'
    },
    slider: {
      display: 'flex',
      animation: animationStyle
    }
  }))();

  return compiledStyles;
};

const InfiniteCarousel = (props) => {
  const { children, direction } = props;
  const classes = useStyles(direction);

  return (
    <div className={classes.carouselWrapper}>
      <div className={classes.slider}>
        {children}
        {children}
      </div>
    </div>
  );
};

InfiniteCarousel.propTypes = {
  children: PropTypes.any,
  direction: PropTypes.string
};

export default InfiniteCarousel;
