import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { NAVIGATION_VARIANTS } from 'src/webkit/constants/NavigationVariants';
import { COLORS } from 'src/webkit/foundation/Colors';
import { useHistory } from 'react-router';
import OutlinedPillButton from 'src/webkit/components/buttons/OutlinedPillButton';
import { BUTTON_VARIANTS } from 'src/webkit/constants/ButtonVariants';
import Typography from 'src/webkit/foundation/Typography';
import DefaultLogoInverted from '../../foundation/logos/logo-default-inverted.svg';
import DefaultLogoPrimary from '../../foundation/logos/logo-default-primary.svg';

const getBackground = (style) => {
  switch (style) {
    case NAVIGATION_VARIANTS.STYLES.PRIMARY:
      return COLORS.PRIMARY.default;
    case NAVIGATION_VARIANTS.STYLES.SECONDARY:
      return COLORS.WHITE.default;
    case NAVIGATION_VARIANTS.STYLES.TERTIARY:
      return COLORS.BLACK[5];
    case NAVIGATION_VARIANTS.STYLES.DARK:
      return COLORS.BLACK[100];
    default:
      return COLORS.PRIMARY.default;
  }
};

const getColor = (style) => {
  switch (style) {
    case NAVIGATION_VARIANTS.STYLES.PRIMARY:
      return COLORS.WHITE.default;
    case NAVIGATION_VARIANTS.STYLES.SECONDARY:
      return COLORS.PRIMARY.default;
    case NAVIGATION_VARIANTS.STYLES.TERTIARY:
      return COLORS.PRIMARY.default;
    case NAVIGATION_VARIANTS.STYLES.DARK:
      return COLORS.WHITE.default;
    default:
      return COLORS.WHITE.default;
  }
};

const getLogo = (style) => {
  switch (style) {
    case NAVIGATION_VARIANTS.STYLES.PRIMARY:
      return DefaultLogoInverted;
    case NAVIGATION_VARIANTS.STYLES.SECONDARY:
      return DefaultLogoPrimary;
    case NAVIGATION_VARIANTS.STYLES.TERTIARY:
      return DefaultLogoPrimary;
    case NAVIGATION_VARIANTS.STYLES.DARK:
      return DefaultLogoInverted;
    default:
      return DefaultLogoInverted;
  }
};

const getButtonStyle = (style) => {
  switch (style) {
    case NAVIGATION_VARIANTS.STYLES.PRIMARY:
      return BUTTON_VARIANTS.STYLES.INVERTED;
    case NAVIGATION_VARIANTS.STYLES.SECONDARY:
      return BUTTON_VARIANTS.STYLES.PRIMARY;
    case NAVIGATION_VARIANTS.STYLES.TERTIARY:
      return BUTTON_VARIANTS.STYLES.PRIMARY;
    case NAVIGATION_VARIANTS.STYLES.DARK:
      return BUTTON_VARIANTS.STYLES.INVERTED;
    default:
      return BUTTON_VARIANTS.STYLES.INVERTED;
  }
};

const useStyles = (props) =>
  makeStyles(() => ({
    root: {
      fontFamily: 'Elza',
      width: '100%',
      background: props.bgColor,
      padding: '2rem 5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    leftContainer: {
      height: '1.5rem',
      width: 'fit-content',
      '& img': {
        height: '24px',
        width: 'auto'
      }
    },
    rightContainer: {
      display: 'flex',
      gap: '2rem',
      alignItems: 'center',
      justifyContent: 'end'
    },
    link: {
      ...Typography.p,
      textDecoration: 'none',
      color: props.textColor
    },
    plainButton: {
      ...Typography.p,
      background: 'none',
      color: props.textColor,
      border: 'none',
      outline: 'none',
      cursor: 'pointer'
    },
    imageLink: {
      textDecoration: 'none'
    }
  }));

function DefaultNavigation({
  overrideStyles,
  style = NAVIGATION_VARIANTS.STYLES.PRIMARY
}) {
  const history = useHistory();
  const bgColor = getBackground(style);
  const textColor = getColor(style);
  const logo = getLogo(style);
  const buttonStyle = getButtonStyle(style);

  const classes = useStyles({ bgColor, textColor })();

  const redirectSignIn = () => {
    history.push('/app');
  };

  const redirectSignUp = () => {
    history.push('/register');
  };

  const redirectHome = () => {
    history.push('/');
  };

  const redirectAbout = () => {
    history.push('/about');
  };

  const redirectProgrammatic = () => {
    history.push('/programmatic');
  };

  return (
    <div className={classes.root} style={overrideStyles}>
      <div className={classes.leftContainer}>
        <button
          className={classes.plainButton}
          onClick={redirectHome}
          type="button"
        >
          <img src={logo} alt="Relentlo Logo" />
        </button>
      </div>
      <div className={classes.rightContainer}>
        <button
          className={classes.plainButton}
          type="button"
          onClick={redirectHome}
        >
          Home
        </button>
        <button
          className={classes.plainButton}
          type="button"
          onClick={redirectAbout}
        >
          About
        </button>
        <button
          className={classes.plainButton}
          type="button"
          onClick={redirectProgrammatic}
        >
          Programmatic
        </button>
        <button
          className={classes.plainButton}
          type="button"
          onClick={redirectSignIn}
        >
          Sign In
        </button>
        <OutlinedPillButton
          title="Sign Up"
          style={buttonStyle}
          onClick={redirectSignUp}
          type="button"
        />
      </div>
    </div>
  );
}

DefaultNavigation.propTypes = {
  overrideStyles: PropTypes.any,
  style: PropTypes.number
};

export default DefaultNavigation;
