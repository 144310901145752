import React from 'react';
import { makeStyles } from '@material-ui/core';

import { COLORS } from 'src/webkit/foundation/Colors';
import Typography from 'src/webkit/foundation/Typography';
import InfiniteCarousel from './InfiniteCarousel';
import Card from './Card';

const useStyles = makeStyles(() => ({
  ...Typography,
  root: {
    fontFamily: 'Elza',
    background: COLORS.BLACK[5],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '5rem 0',
    overflowX: 'hidden'
  },
  partnersDisplayContainer: {
    marginTop: '4rem',
    width: '100%',
    '& > *:not(:first-child)': {
      marginBlockStart: '1rem'
    }
  },
  textContainer: {
    marginLeft: '5rem'
  },
  bodyText: {
    ...Typography.p,
    marginTop: '1rem',
    maxWidth: '500px'
  }
}));

const Partner = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        <h3 className={classes.h3}>Partners</h3>
        <p className={classes.bodyText}>
          Join other industry-leading and groundbreaking organizations
          rethinking their branded experiences with Relentlo
        </p>
      </div>
      <div className={classes.partnersDisplayContainer}>
        <InfiniteCarousel>
          <Card src="/static/home/partnerlogos/amazon.svg" />
          <Card src="/static/home/partnerlogos/kitkat.svg" />
          <Card src="/static/home/partnerlogos/fanduel.svg" />
          <Card src="/static/home/partnerlogos/fedex.svg" />
          <Card src="/static/home/partnerlogos/homedepot.svg" />
          <Card src="/static/home/partnerlogos/swft.svg" />
          <Card src="/static/home/partnerlogos/experian.svg" />
        </InfiniteCarousel>
        <InfiniteCarousel direction="left">
          <Card src="/static/home/partnerlogos/breinfuel.svg" />
          <Card src="/static/home/partnerlogos/fredsegal.svg" />
          <Card src="/static/home/partnerlogos/mana.svg" />
          <Card src="/static/home/partnerlogos/mission.svg" />
          <Card src="/static/home/partnerlogos/rethink.svg" />
          <Card src="/static/home/partnerlogos/GA.svg" />
          <Card src="/static/home/partnerlogos/flowplay.svg" />
        </InfiniteCarousel>
        <InfiniteCarousel>
          <Card src="/static/home/partnerlogos/fortnite.svg" />
          <Card src="/static/home/partnerlogos/minecraft.svg" />
          <Card src="/static/home/partnerlogos/roblox.svg" />
          <Card src="/static/home/partnerlogos/haagen.svg" />
          <Card src="/static/home/partnerlogos/avakin.svg" />
          <Card src="/static/home/partnerlogos/wal.svg" />
          <Card src="/static/home/partnerlogos/vita.svg" />
        </InfiniteCarousel>
      </div>
    </div>
  );
};

export default Partner;
