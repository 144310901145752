// eslint-disable-next-line import/prefer-default-export
export const BUTTON_VARIANTS = {
  STYLES: {
    PRIMARY: 1,
    SECONDARY: 2,
    TERTIARY: 3,
    INVERTED: 4
  },
  SIZES: {
    DEFAULT: 1,
    COMPACT: 2
  }
};
