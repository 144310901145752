import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import { COLORS } from 'src/webkit/foundation/Colors';
import DefaultCTA from 'src/webkit/layouts/CTA/DefaultCTA';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Elza',
    width: '100%',
    position: 'relative',
    overflowX: 'hidden',
    background: COLORS.BLACK[5]
  },
  wrapper: {
    height: 'fit-content',
    padding: '0rem 5rem',
    paddingBottom: '2.5rem',
    zIndex: '10'
  },
  bg: {
    background: COLORS.PRIMARY.default,
    height: '50%',
    width: '100%',
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: '0'
  }
}));

const CTA = () => {
  const classes = useStyles();
  const history = useHistory();

  const redirectSignUp = () => {
    history.push('/register');
  };

  return (
    <div className={classes.root}>
      <div className={classes.bg}>&nbsp;</div>
      <div data-aos="zoom-out" className={classes.wrapper}>
        <DefaultCTA
          title="Level Up With Relentlo"
          description="Join hundreds of other brands and games to shape the future of metaverse advertising today."
          buttonTitle="Get Started"
          buttonAction={redirectSignUp}
          img="/static/cta_character.png"
          bg={COLORS.WHITE.default}
        />
      </div>
    </div>
  );
};

export default CTA;
