import React from 'react';
import { useLocation, matchPath } from 'react-router';
import { useSelector } from 'react-redux';
import { List, makeStyles } from '@material-ui/core';
import {
  Grid as GridIcon,
  PieChart as PieChartIcon,
  CheckSquare as CheckSquareIcon,
  Settings as SettingsIcon
} from 'react-feather';
import NavItem from './NavItem';
import Typography from 'src/webkit/foundation/Typography';

const items = [
  {
    title: 'Overview',
    icon: PieChartIcon,
    href: '/app/game/overview'
  },
  // {
  //   title: 'Placements',
  //   icon: GridIcon,
  //   href: '/app/game/placements'
  // },
  {
    title: 'Compliance',
    icon: CheckSquareIcon,
    href: '/app/game/compliance'
  },
  {
    title: 'Settings',
    icon: SettingsIcon,
    href: '/app/game/settings'
  }
];

const useStyles = makeStyles(() => ({
  ...Typography,
  nav: {
    padding: '1em 0.5em',
    position: 'sticky',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh'
  },
  logo: {
    width: '45px',
    height: 'auto',
    margin: '2em 0'
  },
  docs: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    bottom: '10px',
    left: '10px',
    width: 'calc(100% - 20px)',
    padding: '1.25em 1.25em',
    background: '#fff',
    borderRadius: '0.5em'
  },
  docDescription: {
    ...Typography.p,
    fontWeight: 600,
    color: '#52575C',
    marignBottom: '0.5rem'
  },
  link: {
    ...Typography.p,
    color: '#4019DC',
    textDecoration: 'none'
  }
}));

function renderNavItems({ items, ...rest }) {
  return (
    <List key="Programmatic Dash">
      {items.map((item) => reduceRoutes({ item, ...rest }), [])}
    </List>
  );
}

function reduceRoutes({ pathname, item, depth = 0 }) {
  const key = item.title + depth;
  const open = matchPath(pathname, {
    path: item.href,
    exact: false
  });
  return (
    <NavItem
      depth={depth}
      href={item.href}
      icon={item.icon}
      key={key}
      info={item.info}
      title={item.title}
    />
  );
}

function SideBar() {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);

  return (
    <div className={classes.nav}>
      <img className={classes.logo} alt="Logo" src="/static/logo-plain.svg" />
      <div className={classes.navItems}>
        {renderNavItems({
          items,
          pathname: location.pathname
        })}
      </div>
      <div className={classes.docs}>
        <p className={classes.docDescription}>Need help?</p>
        <a
          className={classes.link}
          href="https://relentlo.com/docs"
          target="_blank"
          rel="noreferrer"
        >
          Check out our docs
        </a>
      </div>
    </div>
  );
}

export default SideBar;
