/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Briefcase as BriefcaseIcon,
  BarChart as BarChartIcon,
  User as UserIcon,
  Edit as EditIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  ShoppingBag as ShoppingBagIcon,
  Box as BoxIcon,
  Image as ImageIcon,
  Gift as GiftIcon,
  Clipboard as ClipboardIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import ApartmentIcon from '@material-ui/icons/Apartment';

const navConfigBrand = [
  {
    subheader: 'Hub',
    items: [
      {
        title: 'Brand Dashboard',
        icon: BarChartIcon,
        href: '/app/brand/branddashboard'
      },
      {
        title: 'Partnered Games',
        icon: SportsEsportsIcon,
        href: '/app/brand/partnered-games'
      }
    ]
  },
  {
    subheader: 'Profile',
    href: '/app/brand/pages',
    items: [
      {
        title: 'Account Settings',
        href: '/app/brand/account',
        icon: UserIcon
      },
      {
        title: 'Campaigns',
        href: '/app/brand/campaigns',
        icon: ShoppingBagIcon
      },
      {
        title: 'New Campaign',
        href: '/app/brand/create-campaign',
        icon: AddToPhotosIcon
      }
    ]
  },

  {
    subheader: 'Explore',
    items: [
      {
        title: 'Profile',
        href: `/app/explore/user/`,
        icon: BriefcaseIcon
      },
      {
        title: 'Browse Posts',
        href: '/app/explore/browse',
        icon: ShareIcon
      }
    ]
  }
];

const navConfigGame = [
  {
    subheader: 'Hub',
    items: [
      {
        title: 'Item Dashboard',
        icon: PieChartIcon,
        href: '/app/hub/itemdashboard'
      },
      {
        title: 'Game Dashboard',
        icon: BarChartIcon,
        href: '/app/hub/gamedashboard'
      },
      {
        title: 'Asset Manager',
        href: '/app/hub/assets',
        icon: ImageIcon
      }
    ]
  },
  {
    subheader: 'Profile',
    href: '/app/pages',
    items: [
      {
        title: 'Account Settings',
        href: '/app/game/account',
        icon: UserIcon
      }
    ]
  },
  {
    subheader: 'Explore',
    items: [
      {
        title: 'Profile',
        href: `/app/explore/user/`,
        icon: BriefcaseIcon
      },
      {
        title: 'Browse Posts',
        href: '/app/explore/browse',
        icon: ShareIcon
      },
      {
        title: 'Create Post',
        href: '/app/game/explore/create',
        icon: EditIcon
      }
    ]
  }
];

const navConfigStudio = [
  {
    subheader: 'Hub',
    items: [
      {
        title: 'Studio Dashboard',
        icon: BarChartIcon,
        href: '/app/studio/StudioDashboard'
      },
      {
        title: 'All Games',
        icon: SportsEsportsIcon,
        href: '/app/studio/games'
      }
    ]
  },
  {
    subheader: 'Profile',
    href: '/app/studio/pages',
    items: [
      {
        title: 'Account Settings',
        href: '/app/studio/account',
        icon: UserIcon
      }
    ]
  },
  {
    subheader: 'Explore',
    items: [
      {
        title: 'Profile',
        href: `/app/explore/user/`,
        icon: BriefcaseIcon
      },
      {
        title: 'Browse Posts',
        href: '/app/explore/browse',
        icon: ShareIcon
      }
    ]
  }
];

const navConfigCreator = [
  {
    subheader: 'Profile',
    items: [
      {
        title: 'Edit Profile',
        icon: UserIcon,
        href: '/app/creator/editProfile'
      }
    ]
  }
];

const navConfigAdmin = [
  {
    subheader: 'Hub',
    items: [
      {
        title: 'Admin Dashboard',
        icon: BarChartIcon,
        href: '/app/admin/AdminDashboard'
      },
      {
        title: 'All Games',
        icon: SportsEsportsIcon,
        href: '/app/admin/games'
      },
      {
        title: 'All Brands',
        icon: ApartmentIcon,
        href: '/app/admin/brands'
      },
      {
        title: 'Asset Manager',
        icon: ImageIcon,
        href: '/app/admin/assets'
      },
      {
        title: "Surya's Shenanigans",
        icon: BoxIcon,
        href: '/app/admin/actions'
      },
      {
        title: 'All Items',
        icon: GiftIcon,
        href: '/app/admin/items'
      },
      {
        title: 'All Studios',
        icon: ClipboardIcon,
        href: '/app/admin/studios'
      }
    ]
  }
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);
  const typeCapDict = {
    game: 'Game',
    brand: 'Brand',
    studio: 'Studio',
    admin: 'Admin'
  };
  const navConfig =
    user.type === 'game'
      ? navConfigGame
      : user.type === 'brand'
      ? navConfigBrand
      : user.type === 'creator'
      ? navConfigCreator
      : user.email === 'admin@relentlo.com'
      ? navConfigAdmin
      : navConfigStudio;
  const initialProfilePath = '/app/explore/user/';
  const accountPath = '/app/' + user.type + '/account';

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  // change the path for user profile page to append user name
  function editProfileLink(config) {
    config.items[0].href = initialProfilePath + user.name;
    return config.items;
  }

  let content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to={accountPath}>
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatarURL}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to={accountPath}
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {typeCapDict[user.type]}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items:
                  config.subheader === 'Explore'
                    ? editProfileLink(config)
                    : config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
            className="sdk-docs"
          >
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check out our docs!
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;