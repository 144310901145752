/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CAMPAIGN_REQUEST,
  CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN,
  SET_CAMPAIGN_DATA,
  CLEAR_CAMPAIGN_DATA,
  CLEAR_CAMPAIGNS
} from 'src/actions/campaignActions';

const initialState = {
  campaigns: [],
  campaignData: null,
  loading: false
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_REQUEST: {
      return produce(state, (draft) => {
        draft.loading = true;
      });
    }

    case CAMPAIGN_SUCCESS: {
      let { campaigns } = action.payload;
      if (!action.payload.campaigns) {
        campaigns = state.campaigns;
      }

      return produce(state, (draft) => {
        draft.campaigns = campaigns;
        draft.loading = false;
      });
    }

    case DELETE_CAMPAIGN: {
      const { campaignID } = action.payload;

      return produce(state, (draft) => {
        draft.campaigns = draft.campaigns.filter((value, index, arr) => {
          return value.campaignID !== campaignID;
        });
      });
    }

    case SET_CAMPAIGN_DATA: {
      const { campaignData } = action.payload;

      return produce(state, (draft) => {
        draft.campaignData = { ...draft.campaignData, ...campaignData };
      });
    }

    case CLEAR_CAMPAIGN_DATA: {
      return produce(state, (draft) => {
        draft.campaignData = null;
      });
    }

    case CLEAR_CAMPAIGNS: {
      return produce(state, (draft) => {
        draft.campaigns = [];
        draft.campaignData = null;
      });
    }

    default: {
      return state;
    }
  }
};

export default campaignReducer;
